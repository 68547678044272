import { useEffect, useState } from 'react'
import { connectWallet, getCurrentWalletConnected, mintNFT } from "../utils/interact.js";
import React from 'react'
import Constants from '../constants'
import { getByDisplayValue } from '@testing-library/react';

const Header = ({addWalletListener, userLogin, userLogout, setUserLogin, setWallet, gotoReclamar, handleGoToQuienesSomos, handleGoToPreguntasFrecuentes, showTerms, showLogin, walletAddress, isCorrectWalletConnected, handleGoToProductoNuevo, status, setStatus}) => {
    const handleshowReclamar = () => {
        gotoReclamar();
       }

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();
        setWallet(address);
        setStatus(status);
    
        addWalletListener();
      }, []);
    useEffect(async () => {
        let totalTime = localStorage.getItem('timeRedeem');
     
        const blockTime = () => {
           
                let btnNFTDesktop = document.getElementById('ReclamarDesktop');  
                let btnNFTMobile = document.getElementById('ReclamarMobile');  
            
                btnNFTDesktop.disabled=true;
                btnNFTDesktop.innerHTML= 'Volver en: '+totalTime+'s';
                btnNFTMobile.disabled=true;
                btnNFTMobile.innerHTML= 'Volver en: '+totalTime+'s';
                localStorage.setItem('timeRedeem', totalTime);
                if (totalTime<1) {
                    btnNFTDesktop.innerHTML= 'Mis NFTs';
                    btnNFTDesktop.disabled=false;
                    btnNFTMobile.innerHTML= 'Mis NFTs';
                    btnNFTMobile.disabled= false;
                    localStorage.removeItem("timeRedeem");
                } else {
                    totalTime-=1;
                    setTimeout(blockTime,1000);
                }
            
        }
        if (totalTime !=null) {
            window.addEventListener('load', function () {
              blockTime();
            });
        }   
      }, []);


     

    
   
    return (
    <header>
        <div id="status">{status}</div>   
        <nav className="navbar navbar-expand-md navbar-dark bg-dark" aria-label="Navegación principal">
            <div className="container-fluid">
              <a className="navbar-brand hide-mobile" href="/"><img srcSet={Constants.logo} alt={Constants.title} /></a>
              <div className='d-flex align-items-center justify-content-between w-100 hide show-mobile-flex'>
                <a className="navbar-brand" href="/"><img srcSet={Constants.logo} alt={Constants.title} /></a>
                <button className="btn-terciary" id="ReclamarMobile" style={{display: (walletAddress.length > 0 || userLogin != null) ? 'block' : 'none' }} onClick={handleshowReclamar}>Mis NFTs</button>
                {isCorrectWalletConnected && (
                <button className="btn btn-terciary bnt-lg show-mobile" id="chargeButton" onClick={handleGoToProductoNuevo}><i className="fa fa-cart-plus" aria-hidden="true"></i></button>
                )}
                {walletAddress.length > 0 ? (
                        <button className="btn" type="button" id="walletButton">
                            {walletAddress.length > 0 ? (
                                'Wallet: ' +
                                String(walletAddress).substring(0, 4) +
                                '...' +
                                String(walletAddress).substring(38)
                                ) : (
                                <span>🦊 Conectar Wallet</span>
                                )}
                        </button>
                    ) : userLogin != null ? (
                            <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {userLogin.email}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href='#' onClick={userLogout}>Logout</a></li>
                            </ul>
                        </div>
                        ) : (
                        <div className="w-100">
                        <a onClick={showLogin} className="login-link float-end" title="Login">
                            <img srcSet="/assets/imgs/icons/login-circle.svg" alt="Login" />
                        </a>
                        </div>
                    )}                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>   
              </div>                   
              <div className="collapse navbar-collapse" id="navbarsExample03">
                  <ul className="navbar-nav me-auto mb-2 mb-sm-0 align-items-end ">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="/">Inicio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/index.html#product-list">Productos</a>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link" onClick={handleGoToQuienesSomos}>Quienes Somos</button>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link" onClick={handleGoToPreguntasFrecuentes}>Preguntas Frecuentes </button>
                    </li>                    
                    <li className="nav-item hide-mobile">
                        <button className="btn-terciary" id="ReclamarDesktop" style={{display: (walletAddress.length > 0 || userLogin != null) ? 'block' : 'none' }} onClick={handleshowReclamar}>Mis NFTs</button>
                    </li>
                      {isCorrectWalletConnected && (
                        <li className='hide-mobile'>
                            <button className="btn-terciary" id="chargeButton" onClick={handleGoToProductoNuevo}><i className="fa fa-cart-plus" aria-hidden="true"></i> Artículo</button>
                        </li>
                      )}
                  </ul>
                  <div className='d-flex hide-mobile'>
                   {walletAddress.length > 0 ? (
                    <button className="btn" type="button" id="walletButton">
                            {walletAddress.length > 0 ? (
                                'Wallet: ' +
                                String(walletAddress).substring(0, 4) +
                                '...' +
                                String(walletAddress).substring(38)
                                ) : (
                                <span>🦊 Conectar Wallet</span>
                                )}
                        </button>
                    ) : userLogin != null ? (
                            <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {userLogin.email}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href='#' onClick={userLogout}>Logout</a></li>
                            </ul>
                        </div>
                            
                        
                        ) : (
                        <a onClick={showLogin} className="login-link" title="Login">
                            <img srcSet="/assets/imgs/icons/login-circle.svg" alt="Login" />
                        </a>
                    )}                    
                  </div>
              </div>
            </div>
        </nav>   
          
    </header>      
    )
}

    

export default Header
