import React from "react";
import Constants from '../constants'

const adultPopup = ({ yesAdultPopup, noAdultPopup }) => {
  return (
    <div className="popup">
      <div className="popup-content w-100">
        <div className="container text-center">
          <img srcSet={Constants.logo} alt={Constants.title} width="100" />
        </div>
        <h2>¿Sos mayor de edad?</h2>
        <div className="container text-center">
          <p>Al confirmar ser mayor de edad manifiestás tu conformidad con los términos de confidencialidad y el uso de cookies de este sitio web. Para más información, podés consultar nuestros Términos y Condiciones de uso del sitio y nuestra Política de Privacidad.</p>
          <p>Beber con moderación. Prohibida su venta a menores de 18 años.</p>
        </div>
        <div className="buttons mt-4">
          <button className="btn btn-green" onClick={yesAdultPopup}>SI</button>
          <button className="btn btn-red" onClick={noAdultPopup}>NO</button>
        </div>
      </div>
    </div>
  );
};

export default adultPopup;



