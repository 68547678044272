import React from "react";

const messagePopup = ({ closeMessagePopup, message, title }) => {
  return (
    <div className="popup" style={{minHeight: '100px'}}>
      <div className="popup-content w-100" style={{minHeight: '100px'}}>
        <h2>{title}</h2>
        <div className="container text-center">
          <p>{message}</p>
        </div>
        <div className="buttons mt-4">
          <button className="btn btn-info" onClick={closeMessagePopup}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};

export default messagePopup;



