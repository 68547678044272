import React from 'react'
import Constants from '../constants'

const Client = () => {
  return (
    <div className="clients-slider">
        <ul>
            {Constants.clients.map((element, index) => {
                return (
                    <li key={index}>
                        <img src={element.icon} alt={element.title} title={element.title} />
                    </li>              
                );
            })}
        </ul>
    </div>
  )
}

export default Client
