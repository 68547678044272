import React, { useState } from 'react';
import Constants from '../constants';
import { modifyTokenURIAndSet, modifyTokenURIfromCard  } from "../utils/interact.js";
import axios from 'axios';

const Redeem = ({wallet, TokenIdRedeem, DescriptionRedeem, ContractAddressRedeem, rejectRedeem, exitRedeem, BodegaTypeRedeem}) => {
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  const [canjeDisplay, setCanjeDisplay] = useState(0);
 console.log ('Bodegatype in Redeem', BodegaTypeRedeem)
  const acceptRedeem = async () => {
    // Llama a la función para modificar el tokenURI y pasar el tokenId
   // modifyTokenURIAndSet(nft.id.tokenId);
   setCanjeDisplay(1);
   if (wallet) {
    
     const tokenURI = await modifyTokenURIAndSet(TokenIdRedeem, BodegaTypeRedeem);
   // const tokenURI = await modifyTokenURIfromCard(TokenIdRedeem, BodegaTypeRedeem); }
  
   console.log('Paso 2');
   if (tokenURI !=null) {
      const response = await axios.post('https://maticpost-e512fcf20155.herokuapp.com/enviar-correo', {
        email1: email,
        email2: 'hola@maticmarket.io.com',
        introductionMessage: 'Hola!, Canjeaste tu NFT. Te dejamos los datos de tu transacción.<br><br>'+comment,
        description: DescriptionRedeem, //Falta pasar parámetro
        contractAddress: ContractAddressRedeem, //Falta pasar parámetro
        tokenID: parseInt(TokenIdRedeem),
        transaction: 'Completa', //Falta pasar parámetro
        subject: 'Tu recibo de transacción de Matic Market',
        task: 'Canjeaste',
        option: 'Opcion1',
      });
      console.log('Correo electrónico enviado:', response.data);
      setCanjeDisplay(2);

      //modifyTokenURIAndSet(TokenIdRedeem);
      localStorage.setItem('timeRedeem', Constants.timeRedeem);
     
   }
  } else {
    const tokenURI = await modifyTokenURIfromCard(TokenIdRedeem, BodegaTypeRedeem);
   // const tokenURI = await modifyTokenURIfromCard(TokenIdRedeem, BodegaTypeRedeem); }
  
   console.log('Paso 2 From Card');
   if (tokenURI !=null) {
      const response = await axios.post('https://maticpost-e512fcf20155.herokuapp.com/enviar-correo', {
        email1: email,
        email2: 'hola@maticmarket.io.com',
        introductionMessage: 'Hola!, Canjeaste tu NFT. Te dejamos los datos de tu transacción.<br><br>'+comment,
        description: DescriptionRedeem, //Falta pasar parámetro
        contractAddress: ContractAddressRedeem, //Falta pasar parámetro
        tokenID: parseInt(TokenIdRedeem),
        transaction: 'Completa', //Falta pasar parámetro
        subject: 'Tu recibo de transacción de Matic Market',
        task: 'Canjeaste',
        option: 'Opcion1',
      });
      console.log('Correo electrónico enviado:', response.data);
      setCanjeDisplay(2);

      //modifyTokenURIAndSet(TokenIdRedeem);
      localStorage.setItem('timeRedeem', Constants.timeRedeem);
     
   }

  }
    

  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

return (
        <div className="popup">
        <div className="container p-4" style={{ display: canjeDisplay === 0 ? "inline" : "none" }}>
          <h2>Canjear NFT</h2>
          <div className='row'>
            <div className="col-md-12">
              <p>
                <strong>¡Importante!</strong><br />
                Recordá que para canjear tu NFT, tenes que confirmar en <strong>Metamask</strong>.
              </p>
            </div>
          </div>          
          <div className="row">
              <div className="col-md-12">
                <label>Ingresá tu email:</label>
                <div className="field-row">
                  <input className='form-control' type="email" value={email} onChange={handleEmailChange} required />
                </div>
              </div>
              <div className="col-md-12">
                <label>Comentario:</label>
                <div className="field-row">
                  <textarea className='form-control' value={comment}  onChange={handleCommentChange}></textarea>
                </div>
              </div>
          </div>
          <div className="buttons mt-2">
            <button className="btn" onClick={acceptRedeem}>Confirmar</button>
            <button className="btn btn-secondary" onClick={rejectRedeem}>Cancelar</button>
          </div>
        </div>
        <div className="container p-4" style={{ display: canjeDisplay === 1 ? "inline" : "none" }}>
          <h2>Ya estás canjeando tú NFT</h2>
          <div className='row'>
            
          </div>          
        </div>
        <div className="container p-4" style={{ display: canjeDisplay === 2 ? "inline" : "none" }}>
          <h2>¡Felicitaciones!</h2>
          <div className='row'>
            <div className="col-md-12 text-center">
              <h2>
                ¡Canjeaste tú NFT de manera exitosa!
              </h2>
              <p>Pronto recibirás un email con la información de tu operación.</p>
            </div>
          </div> 
          <div className="buttons mt-2">
            <button className="btn btn-secondary" onClick={exitRedeem}>Continuar</button>
          </div>         
        </div>
      </div>     
  );
};
export default Redeem;
