import { useState } from 'react';
import Redeem from "./components/redeem.jsx";
import { modifyTokenURIAndSet } from "./utils/interact.js";

export const NFTCard = ({ nft, handleReclamar }) => {
    const estadoProducto = nft.metadata.attributes.find(attr => attr.trait_type === 'Productos disponibles')?.value;
    const bodegaType = nft.metadata.attributes.find(attr => attr.trait_type === 'Productor:')?.value;
    const [blockProducto, setBlockProducto] = useState(0); 
    const [timeButton, setTimeButton] = useState();
    //const isEstadoProductoNoReclamado = estadoProducto === 'No Reclamado';
    console.log ('Productos en NFTCard:', estadoProducto)
    
    const goTohandleReclamar = () => {
         handleReclamar(nft.id.tokenId, nft.description, nft.contract.address, bodegaType);
         console.log ('Bodegatype en NFT Card General/Fundador:', bodegaType)
         console.log ('Productos en NFTCard Estado producto:', estadoProducto)
    };

     return (
        
        <div className="col p-4">
            <div className="product">
                <div>
                    <img className="object-cover h-128 w-full rounded-t-md" src={nft.media[0].gateway} ></img>
                </div>
                <div className="product-info">
                    <div className="product-info-wrap">
                    <h2 className="prd-title">{nft.title}<br /><small>Id: {parseInt(nft.id.tokenId)}</small></h2>
                    <p><small>{nft.description}<br /><strong>Productos disponibles: {estadoProducto}</strong></small></p>
                    </div>
                </div>
                <div className="product-action">
                    <button
                        type="button"
                        onClick={goTohandleReclamar}
                        disabled={(estadoProducto === '0')}
                        className={(estadoProducto !== '0') ? 'btn product-redeem' : 'btn product-redeem'}>
                        {estadoProducto !== '0' ? 'Canjear' : 'Ya Canjeado'}
                    </button> 
                </div>
            </div>        
        </div>
    )
}
