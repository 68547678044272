const Constants = {
    title: "Matic Market",
    url: "",
    email: "mibloque@palablockchain.io",
    emailReclamo: "hola@maticmarket.io",
    desiredAddresses: ["0xFe43a36B673449D88ea7303D8074c21aC74095e0", "0x4D7338dE4db07596f8a195f25A224D2308ECeF73", "0x6225C57525b196De5E59627aE814f031a1Ea26dc"],
    defaultCategory: "",
    defaultSearch: "", /* Agregar que pueda venir por querystring */
    whatsApp: "541123957279",
    timeRedeem: 120,
    logo: "assets/imgs/LogoMatic2.png",
    logoFooter: "assets/imgs/logo-pala.png",
    slider: [ // Carrousel home
             {imageMobile:'assets/imgs/MovilLogoMatic1.jpg',image:'assets/imgs/DesktopLogoMatic1.jpg', url:'http://google.com', title: '', text: '', bodega:''},
             {imageMobile:'assets/imgs/MovilLogoMatic2.jpg',image:'assets/imgs/DesktopMaticTuBodegaVirtual.jpg', url:'', title: '', text: '', bodega:''},
             {imageMobile:'assets/imgs/MovilSliderVideos.jpeg',image:'assets/imgs/DesktopSliderVideos.jpg', url:'', title: '', text: '', bodega:''},
             {imageMobile:'assets/imgs/MovilLavaque2.jpg',image:'assets/imgs/Lavaque2.jpg', url:'', title: '', text: '', bodega:''},
             {imageMobile:'assets/imgs/MovilLogoMatic3.jpg',image:'assets/imgs/DesktopMatic3.jpg', url:'', title: '', text: '', bodega:''},
             {imageMobile:'assets/imgs/MovilLavaque1.jpg',image:'assets/imgs/Lavaque1.jpg', url:'', title: '', text: '', bodega:'Elegidos_DL'},
             {imageMobile:'assets/imgs/MovilLavaque2.jpg',image:'assets/imgs/Lavaque2.jpg', url:'', title: '', text: '', bodega:'Elegidos_DL'},
             {imageMobile:'assets/imgs/MovilSliderVideos.jpeg',image:'assets/imgs/DesktopSliderVideos.jpg', url:'', title: '', text: '', bodega:'Elegidos_DL'},
             {imageMobile:'assets/imgs/MovilLavaque3.jpg',image:'assets/imgs/Lavaque3.jpg', url:'', title: '', text: '', bodega:'Elegidos_DL'},
             
            ],
    socialList: [
        {faIcon:'fa-brands fa-square-x-twitter', network: 'Twitter', link: 'https://twitter.com/PalaBlockchain'},
        {faIcon:'fa-brands fa-square-reddit', network: 'Reddit', link: 'https://www.reddit.com/user/palablockchain/'},
        {faIcon:'fa-brands fa-square-instagram', network: 'Instagram', link: 'https://www.instagram.com/maticmarket/?hl=en'},
        {faIcon:'fa-brands fa-linkedin', network: 'Linkedin', link: 'https://www.linkedin.com/'},
        {faIcon:'fa-brands fa-square-youtube', network: 'YouTube', link: 'https://www.youtube.com/channel/UCwxhdwyrfqNlytxKlAsahTg/featured'},
       ],
       steps: [ // pasos footer
       { icon:'assets/imgs/step-1.png', 
         title: 'Paso 1', 
         text: 'Instala 🦊 Metamask y conectá tu wallet.',
         link: 'https://tan-legal-flea-488.mypinata.cloud/ipfs/QmZ7F6HNkqX2Pub1kMyppdakaMYQ6VrJXs6MTkugHnXDGR'},
       { icon:'assets/imgs/step-2.png', 
         title: 'Paso 2', 
         text: 'Agregá Polygon mainnet en tu billetera Metamask.',
         link: 'https://tan-legal-flea-488.mypinata.cloud/ipfs/QmZX1hidF9wZhsjqd62SX9AFcib13T86uPUYnMpFDdyHCq'},
       { icon:'assets/imgs/step-3.png', 
         title: 'Paso 3', 
         text: 'Ahora si! Elegí tu vino en maticmarket.io y "Mintea tu NFT".',
         link: 'https://tan-legal-flea-488.mypinata.cloud/ipfs/QmcES1eQet5aDuVjTXVroFEe1XzmNYfESpNVndsaDKyKGW'},
       { icon:'assets/imgs/step-4.png', 
         title: 'Paso 4', 
         text: '¡Listo es tuyo! Agregalo en Metamask, reclamalo o revendelo.',
         link: 'https://tan-legal-flea-488.mypinata.cloud/ipfs/QmPfoEmD2TiQA4ypsCYVo7SLBG5Jkr9f87Z1L2C9Z9JaDJ'},
       ],
   clients: [ 
       { icon:'assets/imgs/client_1.svg', 
         title: 'Paso 1'},
       { icon:'assets/imgs/client_2.svg', 
         title: 'Paso 2'},
       { icon:'assets/imgs/client_3.svg', 
         title: 'Paso 3'},
       { icon:'assets/imgs/client_4.svg', 
         title: 'Paso 4'},
       { icon:'assets/imgs/client_5.svg', 
         title: 'Paso 4'},
       { icon:'assets/imgs/client_6.svg', 
         title: 'Paso 4'},
       { icon:'assets/imgs/client_7.svg', 
         title: 'Paso 4'},
         { icon:'assets/imgs/client_1.svg', 
         title: 'Paso 1'},
       { icon:'assets/imgs/client_2.svg', 
         title: 'Paso 2'},
       { icon:'assets/imgs/client_3.svg', 
         title: 'Paso 3'},
       { icon:'assets/imgs/client_4.svg', 
         title: 'Paso 4'},
       { icon:'assets/imgs/client_5.svg', 
         title: 'Paso 4'},
       { icon:'assets/imgs/client_6.svg', 
         title: 'Paso 4'},
       { icon:'assets/imgs/client_7.svg', 
         title: 'Paso 4'},
       ],
}
export default Constants