import { connectWallet, getCurrentWalletConnected, mintNFT } from "./utils/interact.js";

import React, { useEffect, useState } from "react";

import Minter from './Minter';
import ProductoNuevo from './producto-nuevo';
import VinoSeleccionado from './VinoSeleccionado.js';
import Header from './components/header';
import Footer from './components/footer';
import QuienesSomos from './quienes-somos';
import PreguntasFrecuentes from './preguntas-frecuentes';
import TerminosCondiciones from './terminos-y-condiciones';
import Constants from './constants';
import Contacto from './contacto';
import Terms from "./components/terms.jsx";
import Reclamar from './Reclamar.js';
import AdultPopup from "./components/adultPopup.jsx";
import MessagePopup from "./components/messagePopup.jsx";

import Login from './auth/Login';
import { db, auth, signOut } from './firebaseConfig.js';
import { doc, getDoc } from "firebase/firestore";


function App() {
  const [currentPage, setCurrentPage] = useState('Minter');
  const [selectedVino, setSelectedVino] = useState([]);
  const [selectedBodega, setSelectedBodega] = useState(Constants.defaultCategory);
  const [selectedVinos, setSelectedVinos] = useState([]);
  const [status, setStatus] = useState('');
  const [showAdultPopup, setShowAdultPopup] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [textMessage, setTextMessage] = useState('');
  
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [userLogin, setUserLogin] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  

  useEffect( async () => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      if (searchParams.get('code') != null && searchParams.get('state') != null) {
          const code = searchParams.get('code');
          const state = searchParams.get('state');
          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json' // Tipo de contenido del cuerpo de la solicitud (JSON en este caso)
            },
            body: JSON.stringify({ code: code, state: state}) // Convierte el objeto JavaScript a JSON y lo establece como cuerpo de la solicitud
          };
          fetch('https://palabackendserver-9e979415d339.herokuapp.com/api/authorization', options)
          .then(response => {
            if (!response.ok) {
              console.log(response);
            }
            setTitleMessage("¡Felicitaciones!");
            setTextMessage("Ahora tus productos en Matic Market podrán ser abonados por tus clientes utilizando MercadoPago.");
            setShowMessagePopup(true);

            return response.json();
          })
          .then(data => {
            // Aquí puedes trabajar con los datos en formato JSON
            console.log(data);
          })
          .catch(error => {
            console.error('Hubo un problema con la petición fetch:', error);
          });               
      }
    }
    catch(error) {
      console.log(error);
    }
}, [])


  

 
  const onlyScrollTo = () => {
    window.scrollTo(0,0);
  }

  const handleGoToMinter = () => {
   onlyScrollTo();
   setCurrentPage('Minter');    
  };

  const handleGoBack = () => {
    onlyScrollTo();
    setCurrentPage('Minter');
  };

  const handleVinoSeleccionado = (wine) => {
    onlyScrollTo();
    setSelectedVino(wine);
    setCurrentPage('VinoSeleccionado');
  };
 
  const handleVinosSeleccionados = (wine) => {
    onlyScrollTo();
    setSelectedVinos(wine);
  };

  const handleGoToProductoNuevo = () => {
    onlyScrollTo();
    setCurrentPage('ProductoNuevo');
  };

  const handleGoToQuienesSomos = () => {
    onlyScrollTo();
    window.ToogleMenu();
    setCurrentPage('QuienesSomos');
  };

  const handleGoToPreguntasFrecuentes = () => {
    onlyScrollTo();
    window.ToogleMenu();
    setCurrentPage('PreguntasFrecuentes');
  };

  const handleGoToTerminosCondiciones = () => {
    onlyScrollTo();
    closeLogin();
    setCurrentPage('TerminosCondiciones');
  };
  
  const handleGoToContacto = () => {
    onlyScrollTo();
    setCurrentPage('Contacto');
  };
  
  const handleGoToVinoSeleccionado = (wine) => {
    onlyScrollTo();
    setSelectedVino(wine);
    setCurrentPage('VinoSeleccionado');
  };

  const handlegotoReclamar = () => {
    onlyScrollTo();
    setCurrentPage('Reclamar');
  };

  /* LOGIN  */
  useEffect(() => {
    auth.onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        setUserLogin(user);
      } else {
        // No user is signed in.
        setUserLogin(null);
      }
    });
  });

  /* CONNECT */
  //const desiredAddresses = Constants.desiredAddresses;
  const desiredAddresses = Constants.desiredAddresses.map(address => address.toLowerCase());
  //const formattedDesiredAddress = desiredAddress.toLowerCase();
  const [isCorrectWalletConnected, setIsCorrectWalletConnected] = useState(false);
  
  const [walletAddress, setWallet] = useState("");
  const [showTermsPopup, setShowTermsPopup] = useState(false);

    useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    window.Back('hide');
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    if (desiredAddresses.includes(walletResponse.address.toLowerCase())) {
      setIsCorrectWalletConnected(true);
    } 
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Elegí tu gran experiencia");
          if (desiredAddresses.includes(accounts[0].toLowerCase())) {
            setIsCorrectWalletConnected(true);
          } else {
          }
        } else {
          setWallet("");
          setStatus("🦊 Conectate a Metamask con el boton 👆");
          setIsCorrectWalletConnected(false);
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`}>
            Presione aqui para instalar Metamask
          </a>
        </p>
      );
    }
  }
  const userLogout = () => {
    signOut(auth);
  }
  const showLogin = () => {
    window.Back('show');
    setShowLoginPopup(true);
  };
  const closeLogin = () => {
    window.Back('hide');
    setShowLoginPopup(false);
  };

  const showTerms = () => {
    //window.Back();
    setShowLoginPopup(false);
    setShowTermsPopup(true);
    
  };

  const acceptTerms = () => {
    userLogout();
    const userAgent = navigator.userAgent;
    if (/Mobi|Android/i.test(userAgent) && !/Metamask/i.test(userAgent)) {
      window.location.href = "https://metamask.app.link/dapp/https://maticmarket.io";
    } else {
      connectWalletPressed();
      // Handle non-mobile user agents
    }
    setShowTermsPopup(false);
  };

  const rejectTerms = () => {
    window.Back();
    setShowTermsPopup(false);
  };
  /* /CONNECT */

  /* ADULT */
  useEffect(() => {
    const adult = localStorage.getItem('adult');
    if (adult==null || adult == 'false') {
      setShowAdultPopup(true);
      window.Back();
    } else {
      setShowAdultPopup(false);
    }
  }, []);

  const yesAdultPopup = () => {
    setShowAdultPopup(false);
    localStorage.setItem('adult','true');
    window.Back();
  };
  const noAdultPopup = () => {
    handleGoToTerminosCondiciones();
    setShowAdultPopup(true);
  };
  /* END ADULT */

  const closeMessagePopup = () => {
    setShowMessagePopup(false);
  };
  

  return (
      <div className="App">
      <a href={"https://api.whatsapp.com/send?phone=" + Constants.whatsApp +"&amp;text=" } target="_blank" id="WPBtn"><img srcSet="/assets/imgs/icon_whatsapp.png" width="70" height="70" alt="WhatsApp" /></a>
      <Header 
        addWalletListener={addWalletListener}
        setWallet={setWallet}
        gotoReclamar={handlegotoReclamar}
        handleGoToQuienesSomos={handleGoToQuienesSomos}
        handleGoToPreguntasFrecuentes={handleGoToPreguntasFrecuentes}
        showTerms={showTerms}
        showLogin={showLogin}
        userLogin={userLogin}
        userLogout={userLogout}
        setUserLogin={setUserLogin}
        walletAddress={walletAddress}
        isCorrectWalletConnected={isCorrectWalletConnected}
        handleGoToProductoNuevo={handleGoToProductoNuevo}
        status={status}
        setStatus={setStatus} />      
         <div className="hdr-topline hdr-topline--dark js-hdr-bottom header-fixed header-fixed-no-active hide hide-mobile">
          <div className="container">
            <div className="row">
              <div className="col text-center">
              {walletAddress.length > 0 ? (
                  <div className="custom-text-item">✅ Ya estás conectado! Ahora podés seleccionar un producto!</div>
                ) : (
                  <div className="custom-text-item">🦊 Conectate a tu billetera <span>Metamask</span>, utilizando el botón "CONECTAR WALLET".</div>
                )}
              </div>
            </div>
          </div>
      </div> 
        {currentPage === 'Minter' && (
          <Minter
            goToNuevaPagina={handleGoToProductoNuevo}
            handleVinoSeleccionado={handleVinoSeleccionado}
            handleGoToVinoSeleccionado={handleGoToVinoSeleccionado}
            handleVinosSeleccionados={handleVinosSeleccionados}
            setSelectedBodega={setSelectedBodega}
            selectedBodega={selectedBodega}
            setStatus={setStatus} />
        )}
        {currentPage === 'ProductoNuevo' && (
          <ProductoNuevo goBack={handleGoToMinter} />
        )}
        {currentPage === 'QuienesSomos' && (
          <QuienesSomos goBack={handleGoToMinter} />
        )}
        {currentPage === 'PreguntasFrecuentes' && (
          <PreguntasFrecuentes goBack={handleGoToMinter} />
        )}
        {currentPage === 'TerminosCondiciones' && (
          <TerminosCondiciones goBack={handleGoToMinter} />
        )}
        {currentPage === 'Contacto' && (
          <Contacto goBack={handleGoToMinter} />
        )}
        {currentPage === 'Reclamar' && (
          <Reclamar userLogin={userLogin} handlegotoReclamar={handlegotoReclamar} goBack={handleGoToMinter} />
        )}
        {currentPage === 'VinoSeleccionado' &&
          <VinoSeleccionado walletAddress={walletAddress} showTerms={showTerms} userLogin={userLogin} showLogin={showLogin} wine={selectedVino} handleVinoSeleccionado={handleGoToVinoSeleccionado} selectedBodega={selectedBodega} handleGoBack={handleGoBack} status={status} setShowMessagePopup={setShowMessagePopup} setTitleMessage={setTitleMessage} setTextMessage={setTextMessage} />
        }
        {showTermsPopup && (
          <Terms acceptTerms={acceptTerms} rejectTerms={rejectTerms}></Terms>
        )}
        {showAdultPopup && <AdultPopup  yesAdultPopup={yesAdultPopup} noAdultPopup={noAdultPopup} />}
        {showLoginPopup && (
          <Login closeLogin={closeLogin} handleGoToTerminosCondiciones={handleGoToTerminosCondiciones} acceptTerms={acceptTerms} walletAddress={walletAddress} showTerms={showTerms} />
        )}
        {showMessagePopup && <MessagePopup  closeMessagePopup={closeMessagePopup} message={textMessage}  title={titleMessage} />}
               
        <Footer 
        handleGoToQuienesSomos={handleGoToQuienesSomos}
        handleGoToContacto={handleGoToContacto}
        handleGoToTerminosCondiciones={handleGoToTerminosCondiciones}
        handleGoToPreguntasFrecuentes={handleGoToPreguntasFrecuentes} />
      </div>        
  );
}

export default App;









