import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth, signOut, setPersistence, browserSessionPersistence, signInWithPopup, signInWithRedirect, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

// Tu configuración de Firebase para la aplicación web
const firebaseConfig = {
  apiKey: "AIzaSyAw7zp9D7oFCeopiliM4xm01xuu0xruORA",
  authDomain: "maticmkttest.firebaseapp.com",
  databaseURL: "https://maticmkttest-default-rtdb.firebaseio.com",
  projectId: "maticmkttest",
  storageBucket: "maticmkttest.appspot.com",
  messagingSenderId: "627688177543",
  appId: "1:627688177543:web:ce29b14460f2c1c1e0dd22",
  measurementId: "G-YNNE5TMVL6"
};

// Initialize Firebase Auth provider
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, analytics, storage, auth, signOut, setPersistence, browserSessionPersistence, googleProvider, facebookProvider, signInWithPopup, signInWithRedirect };
