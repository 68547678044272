import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Constants from '../constants';

const Slider = ({bodega}) => {
  return (
    <div className="holder fullwidth full-nopad mt-0 mb-4">
    <div className="container">
        <div className="bnslider-wrapper">
          
        <Carousel data-bs-theme="light">
        {Constants.slider.filter(slide => slide.bodega === bodega).map((element, index) => {
          return (
            <Carousel.Item key={index}>   
              {(element.url!='') ? 
              <a href={element.url} target='_blank'>
                <picture>
                {/* Imagen para desktop */}
                <source media="(min-width: 768px)" alt={element.title} className="d-block w-100" srcSet={element.image} />
                {/* Imagen para móvil */}
                <source media="(max-width: 767px)" alt={element.title} className="d-block w-100" srcSet={element.imageMobile} />
                {/* Imagen por defecto para navegadores que no soporten la etiqueta <picture> */}
                <img src={element.image} alt={element.title} className="d-block w-100" />
              </picture>  
              </a> : 
                <picture>
                {/* Imagen para desktop */}
                <source media="(min-width: 768px)" alt={element.title} className="d-block w-100" srcSet={element.image} />
                {/* Imagen para móvil */}
                <source media="(max-width: 767px)" alt={element.title} className="d-block w-100" srcSet={element.imageMobile} />
                {/* Imagen por defecto para navegadores que no soporten la etiqueta <picture> */}
                <img src={element.image} alt={element.title} className="d-block w-100" />
              </picture>  }
              
              <Carousel.Caption>
                <h5>{element.title}</h5>
                <p>{element.text}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
        </Carousel>
        </div>
    </div>
    </div>
   
  )
}

export default Slider
