import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { uploadFileToIPFS } from "./utils/pinata";
import { db } from './firebaseConfig';

const ProductoNuevo = ({ setWines, goBack }) => {
  const [formParams, setFormParams] = useState({
    name: '',
    description: '',
    imageUrl: '',
    bodega: '',
    cosecha: '',
    anio: '',
    videoUrl: '',
    price: 0,
    bodegaWallet: '',
    stock: 200,
    cepa: '',
    acidez: 0,
    taninos: '',
    agentefee: 0,
    agentewallet: '',
    prioridad: 0,
    discount: 0,
  });

  async function OnChangeFile(e) {
    var file = e.target.files[0];
    try {
      const response = await uploadFileToIPFS(file);
      if (response.success === true) {
        console.log("Imagen guardada en Pinata: ", response.pinataURL);
        updateFormParams({ ...formParams, imageUrl: response.pinataURL });
      }
    } catch (e) {
      console.log("Error durante la carga del archivo", e);
    }
  }

  async function OnChangeFileVideo(e) {
    var file = e.target.files[0];
    try {
      const response = await uploadFileToIPFS(file);
      if (response.success === true) {
        console.log("Video guardado en Pinata: ", response.pinataURL);
        updateFormParams({ ...formParams, videoUrl: response.pinataURL });
      }
    } catch (e) {
      console.log("Error durante la carga del video", e);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const wineData = { ...formParams };

    try {
      const winesCollectionRef = collection(db, 'wines');
      await addDoc(winesCollectionRef, wineData);
      //setWines((prevWines) => [...prevWines, wineData]);
      //goBack();
      setFormParams({
        name: '',
        description: '',
        imageUrl: '',
        bodega: '',
        cosecha: '',
        anio: '',
        videoUrl: '',
        price: 0,
        bodegaWallet: '',
        stock: 200,
        cepa: '',
        acidez: 0,
        taninos: '',
        agentefee: 0,
        agentewallet: '',
        prioridad: 0,
        discount: 0,
      });

    } catch (error) {
      console.error('Error al agregar el vino:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  function updateFormParams(value) {
    setFormParams(value);
  }

   return (
      <div className="page-content">
        <div className="holder breadcrumbs-wrap mt-0">
          <div className="container">
            <ul className="breadcrumbs">
              <li><a href="index.html">Inicio</a></li>
              <li><span>Crear producto</span></li>
            </ul>
          </div>
        </div>
        <div className="holder">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12">
                <h2 className="text-center">Crear producto</h2>
                <div className="form-wrapper">
                  <p>Ingresá todos los datos del producto que deseas incorporar.</p>
                  <form onSubmit={handleSubmit} className="wineForm">
                    <div className="form-group">
                      <label>Nombre</label>
                      <input type="text" className="form-control" name="name" value={formParams.name} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                      <label>Descripción</label>
                      <textarea name="description" className="form-control" value={formParams.description}  onChange={handleChange} required></textarea>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Imagen: </label>
                          <input type="file" className="form-control" onChange={OnChangeFile} required />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Video: </label>
                          <input type="file" className="form-control" onChange={OnChangeFileVideo} required />
                        </div>
                      </div>
                    </div>                    
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Precio: </label>
                          <input type="number" className="form-control" min="0" step="1" name="price" value={formParams.price} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Stock: </label>
                          <input type="number" className="form-control" min="0" max="1000" step="1" name="stock" value={formParams.stock} onChange={handleChange} required />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">                      
                        <div className="form-group">                      
                          <label>Bodega / Coleccion: </label>
                          <input type="text" className="form-control" name="bodega" value={formParams.bodega} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Wallet colectora 1-100: </label>
                          <input type="text" className="form-control" name="bodegaWallet" value={formParams.bodegaWallet} onChange={handleChange} required />
                        </div>  
                      </div> 
                    </div>    
                    <div className="row">
                      <div className="col-sm-12 col-md-6">                      
                        <div className="form-group">                      
                          <label>Atributo 1 - email: </label>
                          <input type="text" className="form-control" name="cosecha" value={formParams.cosecha} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Atributo 2 - Fecha: </label>
                          <input type="text" className="form-control" name="anio" value={formParams.anio} onChange={handleChange} required />
                        </div>  
                      </div> 
                      <div className="col-sm-12 col-md-6">                      
                        <div className="form-group">                      
                          <label>Atributo 3 - Cepa ej Cabernet: </label>
                          <input type="text" className="form-control" name="cepa" value={formParams.cepa} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Atributo 4 - Productos Disponibles: </label>
                          <input type="number" className="form-control" min="0" max="50" step="1" name="acidez" value={formParams.acidez} onChange={handleChange} required />
                        </div>  
                      </div> 
                      <div className="col-sm-12 col-md-6">                      
                        <div className="form-group">                      
                          <label>Atributo 5 - Fecha tardía: </label>
                          <input type="text" className="form-control" name="taninos" value={formParams.taninos} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Atributo 6 - Agente Fee: </label>
                          <input type="number" className="form-control" min="0" max="6" step="1" name="agentefee" value={formParams.agentefee} onChange={handleChange} required />
                        </div>  
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>AAtributo 7 - Agente wallet 1-10: </label>
                          <input type="text" className="form-control" name="agentewallet" value={formParams.agentewallet} onChange={handleChange} required />
                        </div>  
                      </div> 
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Atributo 8 - Discount: </label>
                          <input type="number" className="form-control" min="0" max="5" step="1" name="discount" value={formParams.discount} onChange={handleChange} required />
                        </div>  
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label>Atributo 9 - Prioridad - Numero: </label>
                          <input type="number" className="form-control" min="0" max="1" step="1" name="prioridad" value={formParams.prioridad} onChange={handleChange} required />
                        </div>  
                      </div>
                    </div>                                    
                    <div className="text-center mb-4 p-2">
                      <button type="submit" className="btn">Agregar producto</button>&nbsp;&nbsp;
                      <button type="button" className="btn btn-secondary" onClick={goBack}>
                        Volver a Home
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
            
            export default ProductoNuevo;

