import React from 'react'

const TerminosCondiciones = () => {
  return (
    <div className="page-content">
      <div className="container">
        <ul className="breadcrumbs">
          <li><a href="/">Inicio</a></li>
          <li><span>Términos y Condiciones</span></li>
        </ul>
      </div>
      <div className="holder">
        <div className="container">
          {/*<!-- Page Title -->*/}
          <div className="page-title text-center">
            <h1>Términos y Condiciones</h1>
          </div>
          {/*<!-- /Page Title -->*/}
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-12 mb-2">
              <iframe src='/files/Terminos-y-condiciones-MATIC-MARKET-v7.pdf' width={'100%'} height={'400px'}></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TerminosCondiciones
