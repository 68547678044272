import React from 'react'

const Contacto = () => {
  return (
    <div className="page-content">
      <div className="container">
        <ul className="breadcrumbs">
          <li><a href="/">Inicio</a></li>
          <li><span>Contacto</span></li>
        </ul>
      </div>
      <div className="holder">
        <div className="container">
          {/*<!-- Page Title -->*/}
          <div className="page-title text-center">
            <h1>Contacto</h1>
          </div>
          {/*<!-- /Page Title -->*/}
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-12">
              <p>mail Matic Market: hola@maticmarket.io</p>
              <p></p>
              <p>mail Pala Blockchain: mibloque@palablockchain.io</p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacto
