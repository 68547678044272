import axios from 'axios';
import { pinJSONToIPFS } from './pinata.js';
import { Network, Alchemy, Wallet, Utils } from 'alchemy-sdk';
import React, { useState } from 'react';
import { BigNumber } from 'bignumber.js';

const PRIVATE_KEY = "77204762c4bff75aa910348e93b1ae100ac836db1a93315fed623b249137241d";
const settings = {
  apiKey: '4YuDtmN6KbKHANZAmMCBMzfiujVgXTGq',
  network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(settings);


const alchemyKey = 'https://polygon-mainnet.g.alchemy.com/v2/4YuDtmN6KbKHANZAmMCBMzfiujVgXTGq';
const { createAlchemyWeb3 } = require('@alch/alchemy-web3');
const web3 = createAlchemyWeb3(alchemyKey);
const wallet = new Wallet(PRIVATE_KEY); // Reemplazar con la clave privada del propietario

const contractABIGeneral = require('../contract-abiMATIC.json');
const contractAddressGeneral = '0x827dBaA0d054262662d195d544d6922FFB065cf5';

const contractABIFundadores = require('../contract-abiMATIC.json');
const contractAddressFundadores = '0x827dBaA0d054262662d195d544d6922FFB065cf5';

//const [isCorrectWalletConnected, setIsCorrectWalletConnected] = useState(true);

const getTokenIdFromTransactionReceipt = async (transactionHash) => {
  try {
    const transactionReceipt = await alchemy.core.getTransactionReceipt(transactionHash);
    if (!transactionReceipt || !transactionReceipt.logs) {
      throw new Error('Transaction receipt or logs not found');
    }

    const logs = transactionReceipt.logs;

    // Suponemos que el evento Transfer tiene un nombre de evento específico
    const transferEvent = logs.find(
      (log) => log.topics[0] === '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
    );

    if (!transferEvent) {
      throw new Error('Transfer event not found in transaction logs');
    }

    // Suponemos que el tokenID se encuentra en el segundo parámetro del evento Transfer
    const tokenID = web3.utils.hexToNumberString(transferEvent.topics[3]);

    return tokenID;
  } catch (error) {
    console.error('Error retrieving tokenID from transaction:', error);
    throw error;
  }
};

const Popup = ({ transaction, contractAddress, name, emailTOP, tokenID, description }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [email, setEmail] = useState('');
  const handleClose = () => {
    setIsOpen(false); // Revisar estado porque cuando cierra no deja mintear nuevamente
  };
  console.log(isOpen)
 
  const handleCopy = (value) => {
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    alert('¡Copiado al portapapeles!');
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSend = async () => {
    try {
      // Enviar el correo electrónico al servidor
      //const response = await axios.post('https://maticpost-e512fcf20155.herokuapp.com/enviar-correo', {
      const response = await axios.post('https://palabackendserver-9e979415d339.herokuapp.com/api/correo', {
        email1: email,
        email2: 'hola@maticmarket.io',
        email3: emailTOP, 
        introductionMessage: 'Hola!, te dejamos debajo los datos de tu transacción.',
        name: name,
        description: description,
        contractAddress: contractAddress,
        tokenID: tokenID,
        transaction: transaction,
        subject: 'Tu recibo de transacción de Matic Market',
        task: 'Adquiriste',
        option: 'Opcion1',
      });
  
      console.log('Correo electrónico enviado:', response.data);
  
      // Mostrar una alerta de éxito
      alert('Correo electrónico enviado exitosamente');
    } catch (error) {
      console.error('Error al enviar el correo electrónico:', error);
  
      // Mostrar una alerta de error con el mensaje de error
      alert('Error al enviar el correo electrónico: ' + error.message);
    }
  };
  
  return (
    <>
      {isOpen && (
        <div className="custom-popup">
          <div className="popup-content p-3">
            <h2>Transacción EXITOSA!  </h2>
            <p className='text-center'>Ahora importa tu NFT a la wallet</p>
            <p>Copia el contrato y hacé click en: Wallet-NFTs-Importar NFT y llena el formulario.</p>
            <div className="row">
              <div className="col-md-12 p-1">
                <label>Dirección de Contrato:</label>
                <div className="field-row">
                  <input className='form-control' type="text" value={contractAddress} readOnly/>
                  <button className='btn' onClick={() => handleCopy(contractAddress)}>Copiar</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 p-1">
                <label>Recordá tu Token ID:</label>
                <div className="field-row">
                    <input className='form-control' type="text" value={tokenID} readOnly />
                </div>
              </div>
              <div className="col-md-8 p-1">
                <label>Verificá la Transacción en:</label>
                <div className="field-row">
                  <input className='form-control' type="text" value={transaction} readOnly />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 p-1">
                <label>Ingresá tu email para recibir tu recibo:</label>
                <div className="field-row">
                  <input className='form-control' type="email" value={email} onChange={handleEmailChange} />
                </div>
              </div>
            </div>
            <div className="buttons p-1">
              <button className='btn' onClick={handleEmailSend}>Enviar Correo</button>
              <button className='btn btn-secondary' onClick={handleClose}>Cerrar</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Popup;


export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const networkID = await window.ethereum.request({
        method: 'eth_chainId',
      });

      if (networkID !== '0x89') { 
        console.log('Networks ID:', networkID);

        // Solicitar al usuario que cambie de red
        const switchNetworkResult = await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x89' }], // ID de la red Mumbai
        });

        if (switchNetworkResult.success) {
          console.log('User switched to Polygon network');

          // Esperar un momento después del cambio de red
          await new Promise(resolve => setTimeout(resolve, 1000));

          // Verificar si el usuario está conectado a la billetera después del cambio de red
          const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });

          if (accounts.length === 0) {
            // El usuario no está conectado, solicitar que se conecte
            throw new Error('User is not connected to the wallet. Please connect to the wallet.');
          }
        } else {
          throw new Error('User rejected network switch');
        }
      }

      console.log('Networks ID:', networkID);
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      const obj = {
        status: '👆🏽 Be sure this is your selection',
        address: addressArray[0],
      };

      return obj;
      
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message,
      };
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`}>
            Presione aqui para instalar Metamask
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
        
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: '👆🏽 Elegí una experiencia y presiona Mint.',
          
        };
      } else {
        return {
          address: '',
          status: '🦊 Conecta tu billetera a Metamask, en tu movil desde el navegador de Metamask',
        };
      }
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message,
      };
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`}>
              Presione aqui para instalar Metamask
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getConnectedUserAddress = async () => {
  try {
    const networkID = await window.ethereum.request({
      method: 'eth_chainId',
    });

    if (networkID !== '0x89') {
      console.log('Networks ID:', networkID);

      // Solicitar al usuario que cambie de red
      const switchNetworkResult = await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }], // ID de la red Mumbai
      });

      // Esperar a que la red cambie completamente (puede llevar un momento)
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Verificar si el usuario está conectado a la billetera después del cambio de red
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      if (accounts.length === 0) {
        // El usuario no está conectado, lanzar un error
        throw new Error('User is not connected to the wallet. Please connect to the wallet.');
      }

      console.log('User switched to Polygon network');
      // Obtener la dirección actualmente seleccionada en Metamask
      const address = accounts[0];
      console.log('Direccion recipient en getConnectedUser', address);
      return {
        success: true,
        address,
      };
    } else {
      // La red es la correcta, proceder normalmente
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      if (accounts.length === 0) {
        // El usuario no está conectado, solicitar que se conecte
        throw new Error('User is not connected to the wallet. Please connect to the wallet.');
      }

      // Obtener la dirección actualmente seleccionada en Metamask
      const address = accounts[0];
      console.log('Direccion recipient en getConnectedUser', address);
      return {
        success: true,
        address,
      };
    }
  } catch (error) {
    console.error('Error al obtener la dirección del usuario conectado:', error);
    return {
      success: false,
      error: `Error al obtener la dirección del usuario conectado: ${error.message || 'Error desconocido'}`,
    };
  }
};

export const mintNFT = async (url, name, description, bodegaType, price, bodegaSelect, stock, cosecha, anio, videoUrl, cepa, acidez, taninos, agentefee, agentewallet, prioridad, discount) => {
 
  // Error handling
  if (url.trim() === '' || name.trim() === '' || description.trim() === '') {
    return {
      success: false,
      status: '❗Verificá haber elegido un vino.',
    };
  }

  // Make metadata
  const metadata = {
    name: name,
    animation_url: videoUrl, // Agregar el campo "video" con la URL del video
    image: url,
    description: description,
    attributes: [
      { trait_type: 'Productos disponibles', value: acidez },
      { trait_type: 'email:', value: cosecha },
      { trait_type: 'Fecha:', value: anio },
      { trait_type: 'Variedad:', value: cepa },
      { trait_type: 'Productor:', value: bodegaType },
      { trait_type: 'Validez:', value: taninos },
   ],

  };

  // Pinata pin request
  const pinataResponse = await pinJSONToIPFS(metadata);
  if (!pinataResponse.success) {
    return {
      success: false,
      status: '😢 Something went wrong while uploading your tokenURI.',
    };
  }
  const tokenURI = pinataResponse.pinataUrl;

  // If para elegir en que contrato escribir
  let contractInstance;
  let contractAddress;
  let contractABI;

  if (bodegaType === 'General') {
    contractInstance = new web3.eth.Contract(contractABIGeneral, contractAddressGeneral);
    contractAddress = contractAddressGeneral;
    contractABI = contractABIGeneral;
  } else {
    contractInstance = new web3.eth.Contract(contractABIFundadores, contractAddressFundadores);
    contractAddress = contractAddressFundadores;
    contractABI = contractABIFundadores;
  }

  if (bodegaType === 'Souvenir') {
    // Obtener el saldo de la billetera conectada con Metamask
    const walletBalance = await web3.eth.getBalance(window.ethereum.selectedAddress);
    const numericBalance = new BigNumber(walletBalance);

    if (numericBalance.toNumber() === 0) {
      // El saldo es igual a 0, entonces llamamos a la función executeClaimReward
      const response = await executeClaimReward();
      if (response) {
        console.log('empieza');
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } else {
      console.log('El saldo no es 0, no se ejecuta executeClaimReward');
    }
  } else {
    console.log('No es Souvenir');
  }

  // Load smart contract
  window.contract = new web3.eth.Contract(contractABI, contractAddress);

  const amountWeiString = price.toString();
  const amountWeiInt = parseInt(amountWeiString);
  //const amountWei = web3.utils.toWei(amount, "ether");

  console.log('Cantidad en MATIC', amountWeiString);

   //value: parseInt(web3.utils.toWei("3","ether")).toString(16)
   
   const amount = amountWeiString;
   // Cargar la variable bodega con lo que viene de minter
  const bodega = bodegaSelect;
  const agent = agentewallet; // CAMBIAR ESTO - Recibir parametro
  const descuento = Number(discount);
  const feeagente = Number(agentefee);

  // Set up Ethereum transaction
  const transactionParameters = {
    to: contractAddress,
    from: window.ethereum.selectedAddress,
    value: parseInt(web3.utils.toWei(amount,"ether")).toString(16),
    data: window.contract.methods
      .safeMint(window.ethereum.selectedAddress, tokenURI, amount, bodega, agent, feeagente, descuento) // saque descueto y feeagente
      .encodeABI(),
  };
  console.log('Parametros:',transactionParameters)
  // Sign transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    let transactionReceipt = null;
    let maxAttempts = 10;
    let attempt = 0;
    console.log('salio transaccion', txHash);
    while (!transactionReceipt & attempt!==maxAttempts) {
      transactionReceipt = await alchemy.core.getTransactionReceipt(txHash);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
      attempt=attempt+1;
      console.log(attempt)
    }
    console.log('Receipt:', transactionReceipt);
    // Obtain the tokenID from the transaction using the appropriate function (getTokenIdFromTransactionReceipt)
    const tokenID = await getTokenIdFromTransactionReceipt(transactionReceipt.transactionHash);
    const statusMessage = `https://polygonscan.com/tx/${txHash}`;
    
    return {
      success: true,
      status: <Popup contractAddress={contractAddress} name={name} tokenID={tokenID} transaction={statusMessage} description={description} emailTOP={cosecha}/>,
    };
  } catch (error) {
    return {
      success: false,
      status: `😥 Mmm algo salió mal: ${error.message}`,
    };
  }
};

export const mintNFTfromCard = async (url, name, description, bodegaType, price, bodegaSelect, stock, cosecha, anio, videoUrl, cepa, acidez, taninos, agentefee, agentewallet, prioridad, discount, mailComprador) => {
  try {
    // Error handling
    if (url.trim() === '' || name.trim() === '' || description.trim() === '') {
      return {
        success: false,
        status: '❗Verify your selection.',
      };
    }
  
    // Make metadata
    const metadata = {
      name: name,
      animation_url: videoUrl,
      image: url,
      description: description,
      attributes: [
        { trait_type: 'Productos disponibles', value: acidez },
        { trait_type: 'email:', value: cosecha },
        { trait_type: 'Fecha:', value: anio },
        { trait_type: 'Variedad:', value: cepa },
        { trait_type: 'Productor:', value: bodegaType },
        { trait_type: 'Validez:', value: taninos },
        { trait_type: 'Ownermail', value: mailComprador },        
      ],
    };

    // Pinata pin request
    console.log(metadata);
    const pinataResponse = await pinJSONToIPFS(metadata);
    if (!pinataResponse.success) {
      return {
        success: false,
        status: '😢 Something went wrong while uploading your tokenURI.',
      };
    }
    const tokenURI = pinataResponse.pinataUrl;
    console.log('TokenURI:', tokenURI);
    
    const Web3 = require('web3');
    const myWeb3 = new Web3();  // Cambia el nombre de la instancia a algo único
    console.log('myWeb3:', myWeb3);

    const amountWeiString = "0";
    const tokenPrice = parseInt(web3.utils.toWei(amountWeiString,"ether")).toString(16);
    console.log('tokenPrice:', tokenPrice);
    const contractABI = require('../contract-abiMATIC.json');
    const contractAddress = '0x827dBaA0d054262662d195d544d6922FFB065cf5';
    const contractInstance = new myWeb3.eth.Contract(contractABI, contractAddress);
    const custodial = "0xFe43a36B673449D88ea7303D8074c21aC74095e0";
    console.log('Datos enviados:', contractABI);

    const bodega = bodegaSelect;
    const agent = agentewallet; // CAMBIAR ESTO - Recibir parametro
    const descuento = Number(discount);
    const feeagente = Number(agentefee);

    const encodedData = contractInstance.methods.safeMint(custodial, tokenURI, tokenPrice, bodega, agent, feeagente, descuento).encodeABI();
    console.log('Data: ',encodedData);
    const nonce = await alchemy.core.getTransactionCount(wallet.getAddress());
    
    // Crea el objeto de transacción
    const transaction = {
      to: contractAddress,
      value: '0x00', // Sin valor en Ether
      gasLimit: "5000000",
      maxPriorityFeePerGas: Utils.parseUnits("700", "gwei"),
      maxFeePerGas: Utils.parseUnits("700", "gwei"),
      nonce: nonce,
      type: 2,
      chainId: 137,
      data: encodedData,
      accessList: [],  // Asegúrate de que accessList esté presente
      v: 1,  // Cambia el valor de v a 1
    };
    
    console.log('Data: ', transaction.data);
    
    // Firma la transacción
    const rawTransaction = await wallet.signTransaction(transaction);
    
    // Envía la transacción
    const txHash = await alchemy.transact.sendTransaction(rawTransaction);
    console.log('Recibo Tx: ', txHash);
    
    // Obtiene el recibo de la transacción directamente del nodo RPC de Polygon
    let transactionReceipt = null;
    let maxAttempts = 10;
    let attempt = 0;

    while (!transactionReceipt & attempt!==maxAttempts) {
      console.log('entro al while');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      transactionReceipt = await alchemy.core.getTransactionReceipt(txHash.hash);
      console.log ('Recibo de Alchemy Mint', transactionReceipt)
     // Wait for 1 second before checking again
      attempt=attempt+1;
      console.log(attempt);
    }

    // Obtain the tokenID from the transaction using the appropriate function (getTokenIdFromTransactionReceipt)
    const tokenID = await getTokenIdFromTransactionReceipt(transactionReceipt.transactionHash);
    const statusMessage = `https://polygonscan.com/tx/${txHash.hash}`;
    console.log(txHash);

    return {
      success: true,
      status: <Popup contractAddress={contractAddress} tokenID={tokenID} transaction={statusMessage} description={description} emailTOP={cosecha} isMintNFT={true}/>,
    };
  } catch (error) {
    return {
      success: false,
      status: `😥 Mmm something went wrong ${error.message}`,
    };
  }
};

export const executeClaimReward = async () => {
  try {
    console.log('Entro a execute Claim');
    
    // Obtener la dirección del usuario conectado
    const recipientInfo = await getConnectedUserAddress();
    const recipient = recipientInfo.address;

    // Verificar si se obtuvo la dirección del usuario
    if (!recipientInfo.success) {
      console.error('No se pudo obtener la dirección del usuario conectado en executeClaimReward');
      return {
        success: false,
        status: 'No se pudo obtener la dirección del usuario conectado en executeClaimReward',
      };
    }

    // Obtener el saldo del destinatario
    const recipientBalance = await alchemy.core.getBalance(recipientInfo.address);
    console.log('Recipient balance',recipientBalance);
   
      // Verificar si el saldo del destinatario es igual a cero
     
        console.log(`El saldo del destinatario ${recipientInfo.address} entro. Se realizará la transacción.`);

        // Dirección del emisor y otros datos necesarios
        const senderAddress = '0xFe43a36B673449D88ea7303D8074c21aC74095e0';
        const contractABI = require('../contract-abiMATIC.json');
        const contractAddress = '0x827dBaA0d054262662d195d544d6922FFB065cf5';
        const contractInstance = new web3.eth.Contract(contractABI, contractAddress);
        const amountToSend = 0.1;
        const amountWei = web3.utils.toWei(amountToSend.toString(), 'ether');

        // Construir la transacción
        const transaction = {
          to: recipient,
          value: amountWei,
          gasLimit: "5000000",
          maxPriorityFeePerGas: Utils.parseUnits("700", "gwei"),
          maxFeePerGas: Utils.parseUnits("700", "gwei"),
          nonce: await alchemy.core.getTransactionCount(wallet.getAddress()),
          type: 2,
          chainId: 137,
        };
        
        console.log('Nonce: ', transaction.nonce);
        console.log('Preparo la transacción');

        // Firmar y enviar la transacción
        const rawTransaction = await wallet.signTransaction(transaction);
        console.log('Raw:', rawTransaction);
        console.log('Sign chainID', transaction.chainId);
        const response = await alchemy.transact.sendTransaction(rawTransaction);

        // Registrar la respuesta en la consola
        console.log('Salió!', response);

        return {
          success: true,
          status: `Recompensa reclamada con éxito para ${recipient}.`,
          response,
        };
  
    
       } catch (error) {
    console.error(`Error al reclamar la recompensa:`, error);
    return {
      success: false,
      status: `Error al reclamar la recompensa: ${error.message}`,
    };
  }
};

export const modifyTokenURIAndSet = async (tokenId, bodega) => {
  try {
    // If para elegir en qué contrato escribir
    console.log('en ModifyTokenUTI');
    let contractInstance;
    let contractAddress;
    console.log('bodega General/Fundadores?:', bodega);
    if (bodega === 'General') {
      contractInstance = new web3.eth.Contract(contractABIGeneral, contractAddressGeneral);
      contractAddress = contractAddressGeneral;
      console.log('If bodega Contract:', contractAddress);
    } else {
      contractInstance = new web3.eth.Contract(contractABIFundadores, contractAddressFundadores);
      contractAddress = contractAddressFundadores;
      console.log('If bodega Contract:', contractAddress);
    }
    console.log('TokenId', tokenId);
    const api_key = "4YuDtmN6KbKHANZAmMCBMzfiujVgXTGq";
    const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${api_key}/getNFTMetadata`;
    const fetchURL = `${baseURL}?contractAddress=${contractAddress}&tokenId=${tokenId}&refreshCache=true`;
    const options = { method: 'GET', headers: { accept: 'application/json' } };

    // Obtener metadata
    let metadata; // Definir la variable de metadata fuera del bloque try
    try {
      const response = await fetch(fetchURL, options);
      console.log('Response: ', response);

      if (response.ok) {
        metadata = await response.json();
        console.log('Metadata: ', metadata);
        // Asegurarse de que la metadata tenga una propiedad 'tokenUri'
        if (metadata && metadata.tokenUri) {
          const rawURL = metadata.tokenUri.raw;

          // Obtener metadata desde la URL cruda
          const rawResponse = await fetch(rawURL);
          if (rawResponse.ok) {
            const rawMetadata = await rawResponse.json();

            // Actualizar el atributo 'Estado del Producto' a 'Reclamado'
            const estadoAttribute = rawMetadata.attributes.find(attr => attr.trait_type === 'Productos disponibles');
            const emailAttribute = rawMetadata.attributes.find(attr => attr.trait_type === 'email');
            console.log('estadoAttribute:', estadoAttribute);

            if (estadoAttribute.value !== 0) {
              estadoAttribute.value = (estadoAttribute.value - 1).toString();
            } else {
              console.error('No hay eventos disponibles');
              return null;
            }

            // Pin la metadata actualizada a Pinata
            const pinataResponse = await pinJSONToIPFS(rawMetadata);
            if (pinataResponse.success) {
              const newTokenURI = pinataResponse.pinataUrl;
              console.log('New TokenURI:', newTokenURI);

              const encodedData = contractInstance.methods.setTokenURI(tokenId, newTokenURI).encodeABI();
              // Actualizar el tokenURI para el tokenId dado
              // await contractInstance.methods.setTokenURI(tokenId, newTokenURI).send({
              
              const parameters = {
                to: contractAddress,
                from: window.ethereum.selectedAddress,
                //gas: 200000, // Ajustar el límite de gas según sea necesario
                data: encodedData
              };

              try {
                const txHash = await window.ethereum.request({
                  method: 'eth_sendTransaction',
                  params: [parameters],
                });

                console.log('Transaction SetTokenURI', txHash);
                // Limpiar el caché
                await invalidateContractCache(contractAddress);

             
                // Refrescar la metadata
                const refresh = await alchemy.nft.refreshNftMetadata(contractAddress, tokenId);
                console.log('Refrescar la metadata:', refresh);

                // Obtener el tokenURI guardado
                const tokenURIsaved = await contractInstance.methods.getTokenURI(tokenId).call();
                console.log ('tokenURIsaved: ', tokenURIsaved);

                if (tokenURIsaved !== null) {
                return tokenURIsaved;
                }


              } catch (error) {
                console.error('Error al enviar la transacción:', error);
                return null;
              }
              // Mostrar canje exitoso si tokenURISaved !== null
            } else {
              console.error('Error al pinear metadata a Pinata.');
              return null;
            }
          } else {
            console.error('Error al obtener metadata desde la URL cruda:', rawResponse.statusText);
            return null;
          }
        } else {
          console.error('No se encontró tokenUri en la metadata.');
          return null;
        }
      } else {
        console.error('Error al obtener NFTs:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error al obtener NFTs:', error);
      return null;
    }
  } catch (error) {
    console.error('Error modificando y configurando el tokenURI:', error);
    return null;
  }
};

export const modifyTokenURIfromCard = async (tokenId, bodega) => {
  try {
    // If para elegir en qué contrato escribir
    console.log('en ModifyTokenURI');
    let contractInstance;
    let contractAddress;
    console.log('bodega General/Fundadores?:', bodega);
    if (bodega === 'General') {
      contractInstance = new web3.eth.Contract(contractABIGeneral, contractAddressGeneral);
      contractAddress = contractAddressGeneral;
      console.log('If bodega Contract:', contractAddress);
    } else {
      contractInstance = new web3.eth.Contract(contractABIFundadores, contractAddressFundadores);
      contractAddress = contractAddressFundadores;
      console.log('If bodega Contract:', contractAddress);
    }
    console.log('TokenId', tokenId);
    const api_key = "4YuDtmN6KbKHANZAmMCBMzfiujVgXTGq";
    const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${api_key}/getNFTMetadata`;
    const fetchURL = `${baseURL}?contractAddress=${contractAddress}&tokenId=${tokenId}&refreshCache=true`;
    const options = { method: 'GET', headers: { accept: 'application/json' } };

    // Obtener metadata
    let metadata; // Definir la variable de metadata fuera del bloque try
    try {
      const response = await fetch(fetchURL, options);
      console.log('Response: ', response);

      if (response.ok) {
        metadata = await response.json();
        console.log('Metadata: ', metadata);
        // Asegurarse de que la metadata tenga una propiedad 'tokenUri'
        if (metadata && metadata.tokenUri) {
          const rawURL = metadata.tokenUri.raw;

          // Obtener metadata desde la URL cruda
          const rawResponse = await fetch(rawURL);
          if (rawResponse.ok) {
            const rawMetadata = await rawResponse.json();

            // Actualizar el atributo 'Estado del Producto' a 'Reclamado'
            const estadoAttribute = rawMetadata.attributes.find(attr => attr.trait_type === 'Productos disponibles');
            const emailAttribute = rawMetadata.attributes.find(attr => attr.trait_type === 'email');
            console.log('estadoAttribute:', estadoAttribute);

            if (estadoAttribute.value !== 0) {
              estadoAttribute.value = (estadoAttribute.value - 1).toString();
            } else {
              console.error('No hay eventos disponibles');
              return null;
            }

            // Pin la metadata actualizada a Pinata
            const pinataResponse = await pinJSONToIPFS(rawMetadata);
            if (pinataResponse.success) {
              const newTokenURI = pinataResponse.pinataUrl;
              console.log('New TokenURI:', newTokenURI);

              const Web3 = require('web3');
              const myWeb3 = new Web3();  // Cambia el nombre de la instancia a algo único

              //const amountWeiString = "0";
              //const tokenPrice = parseInt(web3.utils.toWei(amountWeiString,"ether")).toString(16);

              const contractABI = require('../contract-abiMATIC.json');
              const contractAddress = '0x827dBaA0d054262662d195d544d6922FFB065cf5';
              const contractInstance = new myWeb3.eth.Contract(contractABI, contractAddress);
              const custodial = "0xFe43a36B673449D88ea7303D8074c21aC74095e0";
              
              
              const encodedData = contractInstance.methods.setTokenURI(tokenId, newTokenURI).encodeABI();
              // Actualizar el tokenURI para el tokenId dado
              // await contractInstance.methods.setTokenURI(tokenId, newTokenURI).send({
                console.log('Data: ',encodedData);
                const nonce = await alchemy.core.getTransactionCount(wallet.getAddress());
              
                const transaction = {
                  to: contractAddress,
                  value: '0x00', // Sin valor en Ether
                  gasLimit: "5000000",
                  maxPriorityFeePerGas: Utils.parseUnits("700", "gwei"),
                  maxFeePerGas: Utils.parseUnits("700", "gwei"),
                  nonce: nonce,
                  type: 2,
                  chainId: 137,
                  data: encodedData,
                  accessList: [],  // Asegúrate de que accessList esté presente
                  v: 1,  // Cambia el valor de v a 1
                };
                
                console.log('Data: ', transaction.data);
                
                // Firma la transacción
                const rawTransaction = await wallet.signTransaction(transaction);
                
                // Envía la transacción
                const txHash = await alchemy.transact.sendTransaction(rawTransaction);
                
                console.log('Recibo Tx: ', txHash);

                console.log('Transaction SetTokenURI', txHash);
                // Limpiar el caché
                await invalidateContractCache(contractAddress);

                // Refrescar la metadata
                console.log('Contract Address', contractAddress);
                console.log('tokenId', tokenId);

                const refresh = await alchemy.nft.refreshNftMetadata(contractAddress, tokenId);
                console.log('Refrescar la metadata:', refresh);
              
                // Obtener el tokenURI guardado
                try {
                  const tokenURIsaved = await contractInstance.methods.getTokenURI(tokenId).call();
                  console.log ('tokenURIsaved: ', tokenURIsaved);
  
                  if (tokenURIsaved !== null) {
                    return tokenURIsaved;
                  }
                } catch (error) {
                  console.log('Aca', error);
                  return 1;
                }              
              
              // Mostrar canje exitoso si tokenURISaved !== null
            } else {
              console.error('Error al pinear metadata a Pinata.');
              return null;
            }
          } else {
            console.error('Error al obtener metadata desde la URL cruda:', rawResponse.statusText);
            return null;
          }
        } else {
          console.error('No se encontró tokenUri en la metadata.');
          return null;
        }
      } else {
        console.error('Error al obtener NFTs:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error al obtener NFTs:', error);
      return null;
    }
  } catch (error) {
    console.error('Error modificando y configurando el tokenURI:', error);
    return null;
  }
};


export const invalidateContractCache = async (contractAddress) => {
  try {
    const api_key = "4YuDtmN6KbKHANZAmMCBMzfiujVgXTGq";
    const baseURL = `https://polygon-mainnet.g.alchemy.com/nft/v2/${api_key}/invalidateContract`;
    const fetchURL = `${baseURL}?contractAddress=${contractAddress}`;
    const options = { method: 'GET', headers: { accept: 'application/json' } };

    const response = await fetch(fetchURL, options);
    if (response.ok) {
      const result = await response.json();
      console.log('Cleared Cache:', result);
    } else {
      console.error('Error clearing cache:', response.statusText);
    }
  } catch (error) {
    console.error('Error clearing cache:', error);
  }
};

