//import { connectWallet, getCurrentWalletConnected, mintNFT } from "./utils/interact.js";
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db }       from "./firebaseConfig";

import PopupWelcome from "./PopupWelcome";
import PaginaNueva  from "./producto-nuevo.js";
import Slider       from "./components/slider.jsx";
import Steps      from "./components/steps.jsx";
import Clients      from "./components/clients.jsx";
import Constants    from "./constants.js";

const searchParams = new URLSearchParams(document.location.search);

const Minter = ({
  goToNuevaPagina,
  handleGoToVinoSeleccionado,
  handleGoBack,
  handleVinosSeleccionados,
  selectedBodega,
  setSelectedBodega,
  selectedVinos: initialVinos
}) => {
  // State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  
  const [showPaginaNueva, setShowPaginaNueva] = useState(false);

  
  if (searchParams.get('filter') != null) {
    if (searchParams.get('filter') != "") {
      localStorage.setItem('filter',searchParams.get('filter'));
    } else {
      localStorage.removeItem('filter');
    }
  }
  if (localStorage.getItem('filter') != null) {
    setSelectedBodega(localStorage.getItem('filter'));
  }

  const [name, setName] = useState("");
  const [bodega, setBodega] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState(Constants.logo);
  const [cosecha, setCosecha] = useState("");
  const [anio, setAnio] = useState("");
  const [videoUrl, setVideoURL] = useState("");
  const [price, setPrice] = useState(15);
  const [bodegaWallet, setBodegaWallet] = useState("");
  const [stock, setStock] = useState("");
  const [aroma, setAroma] = useState("Frutal");
  const [acidez, setAcidez] = useState(5);
  const [taninos, setTaninos] = useState(5);
  const [puntaje, setPuntaje] = useState(97);
  const [wines, setWines] = useState([]);
  const [bodegas, setBodegas] = useState([]);

  const [selectedVino, setSelectedVino] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Nuevo state para el término de búsqueda
  const [searchResults, setSearchResults] = useState([]); // Nuevo state para los resultados de búsqueda
  
  
  const isVideoSupported = !!document.createElement("video").canPlayType;

  //useEffect(() => {
   // const userAgent = navigator.userAgent;
   // if (/Mobi|Android/i.test(userAgent) && !/Metamask/i.test(userAgent)) {
   //   setShowWelcomePopup(true);
   // } else {
      // Handle non-mobile user agents
   // }
 // }, []);

 
  useEffect(() => {
    const fetchWines = async () => {
      try {
        const winesRef = collection(db, "wines"); // Replace "wines" with the name of your collection
        const snapshot = await getDocs(winesRef);

        const wines = [];
        snapshot.forEach((doc) => {
          wines.push({ id: doc.id, ...doc.data() });
        });

        setWines(wines);
      } catch (error) {
        console.error("Error fetching wines:", error);
      }
    };
    fetchWines();
  }, []);

  useEffect(() => {
    const fetchBodegas = async () => {
      try {
        const winesRef = collection(db, "wines");
        const snapshot = await getDocs(winesRef);

        const uniqueBodegas = [];
        snapshot.forEach((doc) => {
          const wineData = doc.data();
          if (!uniqueBodegas.includes(wineData.bodega)) {
            uniqueBodegas.push(wineData.bodega);
          }
        });

        setBodegas(uniqueBodegas);
      } catch (error) {
        console.error("Error fetching bodegas:", error);
      }
    };

    fetchBodegas();
  }, []);

  const handleBodegaChange = (event) => {
    const selectedBodegaValue = event.target.value;
    console.log(event.currentTarget.dataset.value);
    setSelectedBodega(selectedBodegaValue);
    //handleBodegaSeleccionada(selectedBodegaValue);
    setSelectedVino("");
  };

  const handleVinoSeleccionado = (wine) => {
    setSelectedVino(wine);
    handleGoToVinoSeleccionado(wine); // Pasa el vino seleccionado a la función handleGoToVinoSeleccionado
  };

  const closeWelcomePopup = () => {
    setShowWelcomePopup(false);
  };

  const handleSearchIconClick = () => {
    setSearchResults([]); // Limpia los resultados de búsqueda al hacer clic en el ícono de búsqueda
    setSearchTerm("");
  };

  useEffect(() => {
    const handleSearch = async () => {
      try {
        const winesRef = collection(db, "wines");
        const snapshot = await getDocs(winesRef);

        const matchingWines = [];
        snapshot.forEach((doc) => {
          const wineData = doc.data();
          if (
            wineData.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            wineData.bodega.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            matchingWines.push({ id: doc.id, ...wineData });
          }
        });

        setSearchResults(matchingWines);
      } catch (error) {
        console.error("Error searching wines:", error);
      }
    };

    if (searchTerm.length > 0) {
      handleSearch();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  return (
    <div>
      <div className="page-content">      
      {!showPaginaNueva && (
        <>
          <div className="main-content">
            <Slider bodega={ localStorage.getItem('filter') != null ? localStorage.getItem('filter'):'' }></Slider>
            <div className="container search-full">
                <div className="form-inline row">
                  <div className="input-group mb-0 p-0">
                    <div className="input-group-prepend category-mobile"  style={{display:localStorage.getItem('filter') != null ? 'none':''}}>
                      <select id="category" className="form-control input-lg search-select" value={selectedBodega} onChange={handleBodegaChange}>
                        <option value="">BODEGAS</option>
                        {bodegas.map((bodega) => (<option key={bodega} value={bodega}>&nbsp;&nbsp;{bodega.toLowerCase()}</option>))}
                      </select>
                    </div>
                    <input className="form-control search-text" type="text" placeholder="Buscar Artículo" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    <div className="input-group-append search-mobile">
                      <button className="btn search-btn"><i className="fa fa-search search-icon" aria-hidden="true"></i></button>
                    </div>                 
                  </div>                  
                </div>
            </div>            
            {(
              <div  className="holder holder-mt-medium section-name-products-grid mb-5">
                <div className="container bg-white">
                  <div className="col-md-12 form" id="product-list"></div>
                  <div className="title-wrap text-center">
                  {searchTerm === "" ? (
                      selectedBodega === "" ? (                      
                        <h2>Nuestra selección de productos</h2>
                     ) :
                        <h2>Productos de {selectedBodega}</h2>
                   ) :
                      <h2>Resultado de la búsqueda:</h2>
                   }
                  </div>
                  <div className="container mb-5">
                    <div className="row d-flex justify-content-center row-cols-2 row-cols-sm-2 row-cols-md-4">
                        {((selectedBodega === "" ? (searchTerm === "" ? wines : searchResults) : wines.filter(wine => wine.bodega === selectedBodega))
                          .filter(wine => wine.name.toLowerCase().includes(searchTerm.toLowerCase()) || wine.bodega.toLowerCase().includes(searchTerm.toLowerCase()) || wine.cepa.toLowerCase().includes(searchTerm.toLowerCase())).length === 0) ? (
                            <div className="row justify-content-center text-center">
                              <p>No hay productos con este criterio</p>
                            </div>                            
                        ) : (
                          (selectedBodega === "" ? (searchTerm === "" ? wines : searchResults) : wines.filter(wine => wine.bodega === selectedBodega))
                            .filter(wine => wine.name.toLowerCase().includes(searchTerm.toLowerCase()) || wine.bodega.toLowerCase().includes(searchTerm.toLowerCase()) || wine.cepa.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map((wine) => (
                            <div key={wine.id} className="col">
                              <div className="product">
                                <div>
                                  <a onClick={() => handleVinoSeleccionado(wine)}>
                                    <img data-src={wine.imageUrl} alt={wine.name} src={wine.imageUrl} />
                                  </a>
                                </div>
                                <div className="product-info">
                                  <div className="product-info-wrap">
                                    <h2 className="prd-title" onClick={() => handleVinoSeleccionado(wine)}>{wine.name}</h2>
                                    <p className="prd-cepa">{wine.cepa}</p>
                                    <div className="row mt-2">
                                      <div className="col-sm-8 col-md-8 col-8">
                                        <p className="prd-bodega">{wine.bodega}</p>
                                      </div>
                                      <div className="col-sm-4 col-md-4 col-4">
                                        <p className="prd-anio">{wine.anio}</p>
                                      </div>
                                    </div>     
                                  </div>
                                </div>
                                <div className="product-action">
                                    <button className="btn product-addtocart" data-price={'<img srcSet="/assets/imgs/icons/shopping-bag.svg" /> ' + wine.price + ' USD'} onClick={() => handleVinoSeleccionado(wine)}><img srcSet="/assets/imgs/icons/shopping-bag.svg" /> {wine.price} USD</button>
                                </div>
                              </div>
                            </div>
                            ))
                        )}
                    </div>
                  </div>                  
                </div>               
              </div>
            )}
               <Steps></Steps>        
              <div className="container product-section text-center">
                <h1>Productos más requeridos</h1>
                <div className="slider-wine">
                    {(wines.filter(wine => wine.prioridad == 1 && (localStorage.getItem('filter') != null ? wine.bodega === localStorage.getItem('filter'):true)).map((wine) => (
                      <div className="slider-item" key={wine.id}>
                        <div className="product">
                          <div>
                            <a onClick={() => handleVinoSeleccionado(wine)}>
                              <img data-src={wine.imageUrl} alt={wine.name} src={wine.imageUrl} />
                            </a>
                          </div>
                          <div className="product-info">
                            <div className="product-info-wrap">
                              <h2 className="prd-title" onClick={() => handleVinoSeleccionado(wine)}>{wine.name}</h2>
                              <p className="prd-cepa">{wine.cepa}</p>
                              <div className="row mt-2">
                                <div className="col-sm-8 col-md-8 col-8">
                                    <p className="prd-bodega">{wine.bodega}</p>
                                </div>
                                <div className="col-sm-4 col-md-4 col-4">
                                  <p className="prd-anio">{wine.anio}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="product-action">
                              <button className="btn product-addtocart" data-price={'<img srcSet="/assets/imgs/icons/shopping-bag.svg" /> ' +wine.price + ' USD'} onClick={() => handleVinoSeleccionado(wine)}><img srcSet="/assets/imgs/icons/shopping-bag.svg" /> {wine.price} USD</button>
                          </div>
                        </div>
                      </div>
                      ))
                    )}
                </div>
            </div>    
            <Clients></Clients> 
            {showWelcomePopup && <PopupWelcome closeWelcomePopup={closeWelcomePopup} />}
          </div>
        </>
      )}
      {showPaginaNueva && <PaginaNueva />}     
    </div>
  </div>    
  );  
};

export default Minter;





