import React, { useEffect, useState } from "react";
import { doc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebaseConfig";
import { mintNFT, mintNFTfromCard } from "./utils/interact.js";
import Carousel from 'react-bootstrap/Carousel';
import Step from "./components/steps.jsx";

import Pay from './auth/Pay';
import MPCheckout from "./mp/checkout.jsx";

const VinoSeleccionado = ({ walletAddress, showLogin, userLogin, showTerms,  wine, handleVinoSeleccionado, handleGoBack, setTitleMessage, setTextMessage, setShowMessagePopup }) => {
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [stock, setStock] = useState(wine.stock);
  const [wines, setWines] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(1); // Inicializado en 1 por defecto
  const [dolarArsRate, setDolarRate] = useState(1255); // Inicializado en 0 por defecto

  const [showPayPopup, setShowPayPopup] = useState(false);
  const [showMPPopup, setShowMPPopup] = useState(false);
  const [mpStatus, setMPStatus] = useState(false);

  const [pay, setPay] = useState(false);


  const [preferenceId, setPreferenceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  if (!wine) {
    // Manejar el caso cuando 'wine' es null
    // return null;
  }

  const { name, description, imageUrl, videoUrl, price, bodegaWallet, bodega, cosecha, anio, cepa, acidez, taninos, agentefee, agentewallet, prioridad, discount } = wine;

  const fetchExchangeRate = async () => {
    try {
      const response = await fetch("https://api.binance.com/api/v3/ticker/price?symbols=[%22MATICUSDT%22]");
      const data = await response.json();
      const maticUsdtRate = parseFloat(data[0].price);
      setExchangeRate(maticUsdtRate);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
  };

  const fetchDolar = async () => {   
    try {
      const response = await fetch("https://dolarapi.com/v1/dolares/blue");
      const data = await response.json();
      setDolarRate(parseFloat(data.venta));
    } catch (error) {
      console.error("Error fetching dolar:", error);
    }
  };

  const fetchStore = async () => {
    const q = query(collection(db, 'stores'), where('index', '==', wine.bodegaWallet));
    await getDocs(q)
      .then(snapshot => {
        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            console.log('Documento encontrado:', doc.id, '=>', doc.data());
            console.log('Seller doc',doc.data().accessToken);
            if (doc.data().accessToken) {
                setMPStatus(true);
            }
          });
        }       
      })
      .catch(error => {
        console.error('Error obteniendo documentos:', error);
      });
  }

  useEffect(async () => {
    await fetchStore();
    await fetchExchangeRate();
    await fetchDolar();
  }, []);



  const convertedPrice1 = (parseFloat(price) / exchangeRate).toFixed(2);

  const convertedPrice = Math.round(convertedPrice1);

  const handleMetaLogin = async () => {
    setShowPayPopup(false);
    showTerms();
  }
  const handlePayPopup = () => {
    window.Back();
    setShowPayPopup(true);
  }
  const handleMPPopup = () => {
    setShowPayPopup(false);
    setShowMPPopup(true);
  }

  const closePay = () => {
    window.Back();
    setShowPayPopup(false);
  }

  const closeMP = () => {
    window.Back();
    setShowMPPopup(false);
  }

  const handleMintNFT = async () => {
    window.Cover('show', 500);
    window.CoverMint('show');
    setStatus(null);

    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });

        if (addressArray.length > 0) {
          const response = await mintNFT(
            imageUrl,
            name,
            description,
            bodega,
            convertedPrice,
            bodegaWallet,
            stock,
            cosecha,
            anio,
            videoUrl,
            cepa,
            acidez,
            taninos,
            agentefee,
            agentewallet,
            prioridad,
            discount
          );

          if (response.success) {
            window.Cover('hide', 500);
            window.CoverMint('hide');
            setStatus(response.status);

            const wineDocId = wine.id;
            const updatedStock = stock - 1;

            await updateStockInDatabase(wineDocId, updatedStock);
            setStock(updatedStock);
          } else {
            window.Cover('hide', 500);
            window.CoverMint('hide');
            setStatusMessage(response.status);
          }
        } else {
          window.Cover('hide', 500);
          window.CoverMint('hide');
          setStatusMessage('🦊 Antes de realizar la compra, asegúrate de hacer clic en el botón <strong>"Login"</strong>, que podés encontrar en la parte superior de la pantalla.<br> Esto te permitirá vincular tu billetera o ingresar con tu login social, y facilitará todo el proceso de compra.');
        }
      } catch (err) {
        window.Cover('hide', 500);
        window.CoverMint('hide');
        setStatusMessage("😥 " + err.message);
      }
    } else {
      window.Cover('hide', 500);
      window.CoverMint('hide');
      setStatusMessage(
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`}>
              Necesitas instalar Metamask 
            </a>
          </p>
        </span>
      );
    }
  };

  const handleMintNFTCard = async () => {
    window.Cover('show', 500);
    window.CoverMint('show');
    setStatus(null);
    closeMP();
   
      try {
        const response = await mintNFTfromCard(
            imageUrl,
            name,
            description,
            bodega,
            convertedPrice,
            bodegaWallet,
            stock,
            cosecha,
            anio,
            videoUrl,
            cepa,
            acidez,
            taninos,
            agentefee,
            agentewallet,
            prioridad,
            discount,
            userLogin.email.toLowerCase()
          );

          if (response.success) {
            window.Cover('hide', 500);
            window.CoverMint('hide');
            setStatus(response.status);

            const wineDocId = wine.id;
            const updatedStock = stock - 1;

            await updateStockInDatabase(wineDocId, updatedStock);
            setStock(updatedStock);
          } else {
            window.Cover('hide', 500);
            window.CoverMint('hide');
            setStatusMessage(response.status);
          }
      } catch (err) {
        window.Cover('hide', 500);
        window.CoverMint('hide');
        setStatusMessage("😥 " + err.message);
      }   
  };

  const updateStockInDatabase = async (documentId, newStock) => {
    try {
      const wineDocRef = doc(db, 'wines', documentId);
      await updateDoc(wineDocRef, { stock: newStock });
      console.log('Stock actualizado en la base de datos.');
    } catch (error) {
      console.error('Error al actualizar el stock en la base de datos:', error);
    }
  };

  const showLoginPay = () => {
    setPay(true);
    showLogin();
  }

  useEffect(() => {
    const fetchWines = async () => {
      try {
        const winesRef = collection(db, "wines");
        const snapshot = await getDocs(winesRef);

        const wines = [];
        snapshot.forEach((doc) => {
          wines.push({ id: doc.id, ...doc.data() });
        });

        setWines(wines);
      } catch (error) {
        console.error("Error fetching wines:", error);
      }
    };
    fetchWines();
  }, []);

  useEffect(() => {
      if (userLogin != null && pay==true) {
        handlePayPopup();
      }
  }, [userLogin]);

  useEffect(() => {
    if (walletAddress.length > 0 != null  && pay==true) {
      handleMintNFT();
    }
}, [walletAddress]);
  

  /* END MP*/
  return (
    <>
<div className="page-content">
      <div className="container">
        <ul className="breadcrumbs">
          <li><a href="/">Inicio</a></li>
          <li><a href="/">{bodega}</a></li>
          <li><span>{name}</span></li>
        </ul>
      </div>
      <div className="holder mt-0 mt-md-5">
        <div className="container">
          <div className="row prd-block" id="prdGallery">
            <div className="col-md-6 col-lg-6">
              <div className="text-center h-100">
                <Carousel data-bs-theme="dark" className="h-100">
                  <Carousel.Item>
                    <img
                      className="d-block product-image-carousel"
                      src={imageUrl}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <video src={videoUrl} id="vinoSeleccionadoVideo" muted loop controls></video>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 mt-1 mt-md-0">
              <div className="prd-block_info prd-block_info--style1">
                <div className="prd-holder prd-block_info_item order-0 mt-15 mt-md-0">
                  <div className="prd-block_title-wrap">
                    <h1 className="prd-page-title">{name}</h1>
                  </div>
                  <p>Stock: {stock}</p>
                  <p className="prd-page-price"><img src="assets/imgs/PNG POLYGON.png" className="image-reduced" alt="Polygon" /> {convertedPrice} MATICs - {price} USDs - {dolarArsRate*price} ARS</p>
                </div>
                <div className="prd-page-description">
                  <p>{description}</p>
                </div>
                <div className="prd-page-info-box">
                  <p>Email:&nbsp;<span>{cosecha}</span></p>
                  <p>Año: <span>{anio}</span></p>
                  <p>Variedad: <span>{cepa}</span></p>
                  <p>#Productos: <span>{acidez}</span></p>
                  <p>Productor: <span>{bodega}</span></p>
                  <p>Validez: <span>{taninos}</span></p>
                </div>
                <div className="prd-block_info_item mb-2 ">
                  {statusMessage && (
                    <div className="prd-block_viewed-wrap d-none d-md-flex mt-2 mb-4 border-error">
                      <div className="prd-block_viewed">
                        <span dangerouslySetInnerHTML={{ __html: statusMessage }}></span>
                      </div>
                    </div>
                  )}
                  {status && (
                    <span>{status}</span>
                  )}
                  <div className="prd-block_actions row">
                    <div className="col-9 col-md-9 col-sm-9">
                    {userLogin != null ? (
                      <button className="btn btn-minter" onClick={handlePayPopup} disabled={stock === 0} title="Mintear un NFT implica la creación y registro único de tú activo digital en la blockchain.">Comprar</button>
                      ) : walletAddress.length > 0 ? (
                      <button className="btn btn-minter" onClick={handleMintNFT} disabled={stock === 0} title="Mintear un NFT implica la creación y registro único de tú activo digital en la blockchain.">Comprar</button>
                    ) : (
                      <button className="btn btn-minter" onClick={showLoginPay} disabled={stock === 0} title="Mintear un NFT implica la creación y registro único de tú activo digital en la blockchain.">Comprar</button>
                    )}
                    </div>
                    <div className="col-3 col-md-3 col-sm-12">
                      <button className="btn btn-minter-back" onClick={handleGoBack}>Volver</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container product-section text-center">
          <h1>Nuestra colección - {bodega}</h1>
          <div className="slider-wine">
            {(wines.filter(wine => wine.bodega === bodega).map((wine) => (
              <div className="slider-item" key={wine.id}>
                <div className="product">
                  <div>
                    <a onClick={() => handleVinoSeleccionado(wine)}>
                      <img data-src={wine.imageUrl} alt={wine.name} src={wine.imageUrl} />
                    </a>
                  </div>
                  <div className="product-info">
                    <div className="product-info-wrap">
                      <h2 className="prd-title" onClick={() => handleVinoSeleccionado(wine)}>{wine.name}</h2>
                      <p className="prd-cepa">{wine.cepa}</p>
                      <div className="row mt-2">
                        <div className="col-sm-8 col-md-8 col-8">
                          <p className="prd-bodega">{wine.bodega}</p>
                        </div>
                        <div className="col-sm-4 col-md-4 col-4">
                          <p className="prd-anio">{wine.anio}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-action">
                    <button className="btn product-addtocart" data-price={'<img srcSet="/assets/imgs/icons/shopping-bag.svg" /> '+wine.price + ' USD'} onClick={() => handleVinoSeleccionado(wine)}><img srcSet="/assets/imgs/icons/shopping-bag.svg" /> {wine.price} USD</button>
                  </div>
                </div>
              </div>
            )))
            }
          </div>
        </div>
        <Step></Step>
      </div>
    </div>
    {showMPPopup && (
          <MPCheckout userLogin={userLogin} wine={wine} handleMintNFTCard={handleMintNFTCard} closeMP={closeMP} setTitleMessage={setTitleMessage} setTextMessage={setTextMessage}  setShowMessagePopup={setShowMessagePopup}  amount={dolarArsRate*price}></MPCheckout>
    )} 
    {showPayPopup && (
          <Pay handleMPPopup={handleMPPopup} handleMetaLogin={handleMetaLogin} closePay={closePay} mpStatus={mpStatus}></Pay>
    )}
    
    </>
    
  );
};

export default VinoSeleccionado;






  
