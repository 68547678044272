import React, { useState } from 'react'
import Constants from '../constants'
import axios from 'axios';

const Footer = ({handleGoToQuienesSomos, 
                 handleGoToPreguntasFrecuentes,
                 handleGoToTerminosCondiciones,
                 handleGoToContacto}) => {

const [email, setEmail] = useState('');
const [placeHolderEmail, setPlaceHolderEmail] = useState('')

const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

const handleEmailSend = async () => {
    try {
        const response = await axios.post('https://maticpost-e512fcf20155.herokuapp.com/enviar-correo', {
        email1: 'hola@maticmarket.io',
        email2: 'mibloque@palablockchain.io',
        introductionMessage: 'Nueva suscripción: ',
        description: 'Email: '+ email,
        contractAddress: '',
        tokenID: '',
        transaction: '',
        subject: 'Nueva Suscripción - maticmarket.io',
        task: 'Adquiriste',
        option: 'Opcion1',
        });
        setEmail('');
        setPlaceHolderEmail('Listo! Ya te encontras suscripto.');
        //alert('Enviado');
    } catch (error) {
        setEmail('');
        setPlaceHolderEmail('Ups, intenta nuevamente.');
        // Mostrar una alerta de error con el mensaje de error
        alert('Error al enviar el correo electrónico: ' + error.message);
    }
};
  return (
    <footer>
    <div className="footer-top">
        <div className="container-fluid">
            <div className="row mt-0">
                <div className="col-lg-7">
                    <div className='row'>
                        <div className="col-md-3 text-center">
                            <a href="/">
                            <img
                                src="assets/imgs/LogoMatic.png"
                                data-srcset={Constants.logoFooter}
                                alt={Constants.title}
                                height="60" // Aumenta la altura en 10mm (10mm ≈ 37.7953 pixels)
                            />
                            </a>
                        </div>
                        <div className='col-md-9'>
                            <h1>¡BIENVENIDOS<br />A MATIC MARKET!</h1>
                            <h2>EL MERCADO DEL FUTURO</h2>
                        </div>
                    </div>                    
                    <div className='row mt-4'>
                        <div className='col-md-9 offset-md-3'>
                            <ul>
                                <li><button className="nav-link" onClick={handleGoToQuienesSomos}>Quienes Somos</button></li>
                                <li><button className="nav-link" onClick={handleGoToTerminosCondiciones}>Términos y Condiciones</button></li>
                                <li><button className="nav-link" onClick={handleGoToPreguntasFrecuentes}>Preguntas Frecuentes</button></li>
                                <li><button className="nav-link" onClick={handleGoToContacto}>Contacto</button></li>
                           </ul>
                        </div>                        
                    </div>                    
                </div>
                <div className="col-lg-5">
                    <div className="">
                        <h4>¡Recibí todas nuestras novedades!</h4>
                        <div className='footer-suscription  mt-3'>
                            <input className='form-control' type="email" value={email} placeholder={placeHolderEmail} onChange={handleEmailChange}></input>
                            <button className='btn' onClick={handleEmailSend}>Enviar</button>
                        </div>
                        <div className="email-footer mt-3">
                            <ul>
                                <li><a href={'mailto:'+Constants.emailReclamo} target='_blank'>{Constants.emailReclamo}</a></li>
                                <li><a href={'mailto:'+Constants.email} target='_blank'>{Constants.email}</a></li>
                            </ul>
                        </div>
                        <ul className="social-links">
                            {Constants.socialList.map((element, index) => {
                                return (
                                    <li key={index}>
                                        <a className={element.faIcon} alt={element.network} href={element.link} target='_blank'></a>
                                    </li>                        
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-copyright text-center mt-4">
           
             
            <img src="assets/imgs/logo-pala-white.png" alt="Logo Pala Blockchain" className="logo-img mt-0" />
            
            <a href="https://www.palablockchain.io/" target="_blank"> Pala Blockchain LLC</a> ©2023 Copyright. Todos los derechos reservados.
            </div>
        </div>
    </div>
    </footer>
  )
}

export default Footer
