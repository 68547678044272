import React from 'react'

const PreguntasFrecuentes = () => {
  return (
    <div className="page-content">
      <div className="container">
        <ul className="breadcrumbs">
          <li><a href="/">Inicio</a></li>
          <li><span>Preguntas Frecuentes</span></li>
        </ul>
      </div>
      <div className="holder">
        <div className="container">
          {/*<!-- Page Title -->*/}
          <div className="page-title text-center">
            <h1>Preguntas Frecuentes</h1>
          </div>
          {/*<!-- /Page Title -->*/}
          <div className="row justify-content-center mb-4">
            <div className="col-lg-8 col-md-10">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      ¿Qué es un NFT?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Un NFT es un certificado digital único que vincula activos físicos con su réplica digital a través de blockchain, asegurando su autenticidad y propiedad.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    ¿Cómo sé que lo que el NFT representa un bien real y de calidad?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    El NFT trae atributos verificados que certifican la autenticidad del producto físico, como una botella de vino con detalles como nombre y cosecha.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    ¿Cómo se determina el valor de los NFTs para los productos físicos?
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Los vendedores en Matic Market establecen los precios basados en la exclusividad y características del producto.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    ¿Puedo obsequiar un NFT de un producto físico?
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Claro, adquiere el NFT en el Marketplace y transfiérelo a la billetera digital del agasajado. Aplica costos de transacción.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    ¿Qué monedas digitales acepta Matic Market para adquirir NFTs?
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Matic Market opera con MATIC de Polygon, facilitando transacciones rápidas y seguras.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    ¿Y si no tengo billetera de criptomonedas?
                    </button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Te ayudamos, Podés adquirir tus NFTs con tarjeta de crédito y nosotros nos encargamos de su custodia.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    ¿Puedo ver mi colección de NFTs  en línea?
                    </button>
                  </h2>
                  <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Sí, tu colección de productos está accesible en tu billetera digital Blockchain o en Marketplaces como Opensea.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    ¿Qué hago si pierdo acceso a mi billetera de NFTs?
                    </button>
                  </h2>
                  <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Guarda tu frase semilla en lugar seguro. Sin ella y la clave, no se puede recuperar el acceso a los NFTs.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingNine">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                    Una vez comprado el NFT,  ¿cómo recibo el producto físico?
                    </button>
                  </h2>
                  <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    El NFT incluye información para contactar al vendedor y coordinar el envío.
                    Los costos de envío son aparte.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    Al comprar un NFT de otro país, ¿quién maneja el envío y aduana?
                    </button>
                  </h2>
                  <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    El comprador y vendedor deben acordar el proceso de envío y gastos aduaneros, que no están incluidos en el NFT.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEleven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                    ¿Cómo funciona la precompra de cosechas con NFTs?
                    </button>
                  </h2>
                  <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    La precompra de cosechas con NFTs te permite adquirir anticipadamente productos de una futura cosecha. Recibes un NFT que representa la compra, y cuando la cosecha esté lista, podrás reclamar el producto físico.
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
   
  )
}

export default PreguntasFrecuentes