import React from "react";
import MetamaskLogo from "./images/PNG METAMASK.png";
import Palalogo from "./images/LogoMatic2.png";
import Corazon from "./images/Corazon 2.png";
import Constants from "./constants";

const PopupWelcome = ({ closePopup }) => {
  const handleButtonClick = () => {
  };

  return (
    <div className="popup-welcome">
      <div className="logos-welcome border-pala">
        <img src={Palalogo} alt={Constants.title} />
        {"  "}
        <img src={Corazon} alt="Corazón" />
        {"  "}
        <img src={MetamaskLogo} alt="Metamask" />
      </div>
      <h2>Sos mayor de edad? {Constants.title}</h2>
      <p>Al confirmar que sos mayor de edad, aceptas los terminos y condiciones de nuestro sitio. los mismos se encuentran en la seccion "Terminos y Condiciones". Bienvenido!<br />Dale a "Ir a <span role="img" aria-label="zorro">🦊</span> Metamask"</p>
      <button className="btn btn-ir-a" onClick={handleButtonClick}>Soy mayor de edad</button>
    </div>
  );
};

export default PopupWelcome;



