import { NFTCard } from "./nftCard";
import { useState, useEffect } from 'react';
import { getCurrentWalletConnected } from "./utils/interact.js";
import Constants from "./constants";
import Redeem from "./components/redeem";
import RedeemfromCard from "./components/redeem";
import e from "cors";

const Reclamar = ({userLogin, handlegotoReclamar, goBack }) => {
  const [wallet, setWalletAddress] = useState("");
  const [NFTs, setNFTs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [TokenIdRedeem, setTokenIdRedeem] = useState();
  const [TokenIdRedeemCard, setTokenIdRedeemCard] = useState();
  const [BodegaTypeRedeem, setBodegaTypeRedeem] = useState();
  const [DescriptionRedeem, setDescriptionRedeem] = useState();
  const [ContractAddressRedeem, setContractAddressRedeem] = useState();
  const contractAddressGeneral = '0x827dBaA0d054262662d195d544d6922FFB065cf5';
  const contractAddressMatic = '0x827dBaA0d054262662d195d544d6922FFB065cf5';

  
  const handleReclamar = (tokenId, descripcion, contractAddress, bodegaType) => {
    window.Cover('show');
    setIsOpen(true);
    setTokenIdRedeem(tokenId);
    setDescriptionRedeem(descripcion);
    setContractAddressRedeem(contractAddress);
    setBodegaTypeRedeem(bodegaType)
  };

  const rejectRedeem = () => {
    window.Cover('hide');
    setIsOpen(false);    
    handlegotoReclamar();
  }

  const exitRedeem = () => {
    window.Cover('hide');
    setIsOpen(false);  
    window.location.reload();
    /*if (tokenId!=null) {
      blockArray.push(tokenId);
      console.log('Block Array', blockArray);
    }*/
  }

  useEffect(() => {
    

    const fetchNFTs = async () => {
      console.log("Fetching NFTs...");
      if (wallet) {
        const api_key = "4YuDtmN6KbKHANZAmMCBMzfiujVgXTGq";
        const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${api_key}/getNFTs/`;
        let random = Math.floor(Math.random() * 100) + 1;
        const fetchURL = `${baseURL}?owner=${wallet}&contractAddresses%5B%5D=${contractAddressMatic}&rand=${random}`; // Opcion con custodial
        console.log(fetchURL);
        const requestOptions = {
          method: 'GET'
        };

        try {
          const response = await fetch(fetchURL, requestOptions);
          if (response.ok) {
            const data = await response.json();

            console.log("NFTs:", data);
            setNFTs(data.ownedNfts);
          } else {
            console.error('Error fetching NFTs:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching NFTs:', error);
        }
      } else {
        const custodial = "0xFe43a36B673449D88ea7303D8074c21aC74095e0";
        const api_key = "4YuDtmN6KbKHANZAmMCBMzfiujVgXTGq";
        const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${api_key}/getNFTs/`;
        let random = Math.floor(Math.random() * 100) + 1;
        const fetchURL = `${baseURL}?owner=${custodial}&contractAddresses%5B%5D=${contractAddressMatic}&rand=${random}`; // Opcion con custodial
        console.log(fetchURL);
        const requestOptions = {
          method: 'GET'
        };

        try {

          const usermail = userLogin.email;  // Reemplazar por la variable de Ale
          console.log("User mail: ", usermail);
          const response = await fetch(fetchURL, requestOptions);
          if (response.ok) {
            const data = await response.json();
            // Filtro data con usermail
            //const datauser = data.ownedNfts.filter(nft => nft.Ownermail === usermail);
            console.log("NFTs:", data.ownedNfts);
            const datauser = data.ownedNfts.filter(nft => nft.metadata.attributes.find(attr => attr.trait_type === 'Ownermail')?.value===usermail);
            console.log("NFTs propios:", datauser);
            
            setNFTs(datauser);
          } else {
            console.error('Error fetching NFTs:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching NFTs:', error);
        }

      }
    };

    const fetchConnectedWallet = async () => {
      if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: 'eth_accounts',
          });
          if (addressArray.length > 0) {
            setWalletAddress(addressArray[0]);
          } else {
            console.log('No se encontró ninguna dirección conectada.');
          }
        } catch (err) {
          console.error('Error al obtener la dirección de la billetera:', err);
        }
      } else {
        console.log('No se detectó MetaMask.');
      }
    };

    fetchConnectedWallet();
    fetchNFTs(); // Llama a fetchNFTs() cuando se obtiene la dirección de la billetera
  }, [wallet]); // Vuelve a llamar fetchNFTs() cuando wallet cambia

  return (
    <div className="page-content">
      <div className="holder breadcrumbs-wrap mt-0">
        <div className="container">
          <ul className="breadcrumbs">
            <li><a href="/">Inicio</a></li>
            <li><span>Canjear NFTs</span></li>
          </ul>
        </div>
      </div>
      <div className="holder">
        <div className="container">
          <h2>NFTs Disponibles</h2>
          <div className="row d-flex row-cols-2 row-cols-sm-2 row-cols-md-5">
              {/*NFTs.length && NFTs.map(nft => <NFTCard nft={nft} key={nft.id.tokenId} />).reverse()*/}
              {NFTs.length && NFTs.filter(nft => nft.metadata.attributes.find(attr => attr.trait_type === 'Productos disponibles')?.value!=='0').map(nft => <NFTCard nft={nft} handleReclamar={handleReclamar} key={`${nft.id.tokenId}-${nft.metadata.title}-${nft.contract.address}`} />).reverse()} {/* Filtrar por mail de usuario */}
          </div>
          <h2>NFTs Canjeados</h2>
          <div className="row d-flex row-cols-2 row-cols-sm-2 row-cols-md-5">
          {NFTs.length && NFTs.filter(nft => nft.metadata.attributes.find(attr => attr.trait_type === 'Productos disponibles')?.value==='0').map(nft => <NFTCard nft={nft} handleReclamar={handleReclamar} key={`${nft.id.tokenId}-${nft.metadata.title}-${nft.contract.address}`} />).reverse()} {/* Filtrar por mail de usuario */}

          </div>
        </div>
      </div>
      {isOpen ? <Redeem wallet={wallet} TokenIdRedeem={TokenIdRedeem} DescriptionRedeem={DescriptionRedeem} ContractAddressRedeem={ContractAddressRedeem} rejectRedeem={rejectRedeem} exitRedeem={exitRedeem} BodegaTypeRedeem={BodegaTypeRedeem} /> : null}
                    
    </div>
    
  );
}

export default Reclamar;

