import React, { useState } from 'react';
import { auth, browserSessionPersistence, setPersistence, googleProvider, facebookProvider, signInWithPopup, signInWithRedirect } from '../firebaseConfig.js';


const Login = ({acceptTerms, handleGoToTerminosCondiciones, showTerms, walletAddress, closeLogin}) => {

  const [terminosAceptados, setTerminosAceptados] = useState(false);
  const [loginHabilitado, setLoginHabilitado] = useState(false);

  const handleCheckboxChange = () => {
    setTerminosAceptados(!terminosAceptados);
    setLoginHabilitado(!loginHabilitado); // Puedes ajustar la lógica según tus necesidades
  };


  const handleLoginWithGoogle = async () => {
    try {
      // Inicia sesión con Google
      //await auth.signInWithRedirect(googleProvider);
      setPersistence(auth, browserSessionPersistence)
      .then(() => {
         // Iniciar sesión con Popup
          signInWithPopup(auth, googleProvider)
          .then((result) => {
            // Al iniciar sesión correctamente con Popup, redirige
          //signInWithRedirect(auth, googleProvider);
          closeLogin();
          })
          .catch((error) => {
            // Manejar errores
            console.error(error);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
      });     

      // El usuario ha iniciado sesión exitosamente
      console.log('Usuario autenticado con Google');
    } catch (error) {
      console.error('Error al autenticar con Google', error.message);
    }
  };

  const handleLoginWithFacebook = async () => {
    try {
      // Inicia sesión con Google
      //await auth.signInWithRedirect(googleProvider);
      // Iniciar sesión con Popup
      signInWithPopup(auth, facebookProvider)
        .then((result) => {
          // Al iniciar sesión correctamente con Popup, redirige
        // console.log(result.user);
         //signInWithRedirect(auth, googleProvider);
         closeLogin();
        })
        .catch((error) => {
          // Manejar errores
          console.error(error);
        });

      // El usuario ha iniciado sesión exitosamente
      console.log('Usuario autenticado con Facebook');
    } catch (error) {
      console.error('Error al autenticar con Facebook', error.message);
    }
  };

  return (
    <div className="popup">
       <div className="popup-close"><a href='#' onClick={closeLogin}>X</a></div>
       <div className="popup-content w-100">
        <div className="login-box">
          <h2>Selecciona tu Login</h2>
          <p>
            <label>
            <input type="checkbox" checked={terminosAceptados} onChange={handleCheckboxChange} />
            &nbsp;Acepto los <a href="#" onClick={handleGoToTerminosCondiciones}>términos y condiciones</a>
            </label>
          </p>
          <a href="#" disabled={!loginHabilitado} onClick={handleLoginWithFacebook} className="social-button" id="facebook-connect"> <span>Conectar con Facebook</span></a>
          <a href="#" disabled={!loginHabilitado} onClick={handleLoginWithGoogle} className="social-button" id="google-connect"> <span>Conectar con Google</span></a>
          <a href="#" disabled={!loginHabilitado} onClick={acceptTerms} className="social-button" id="metamask-connect"> <span>Conectar con Metamask</span></a>
        </div>       
      </div>
    </div>
  );
};

export default Login;