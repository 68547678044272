import React from 'react';

const Pay = ({handleMetaLogin, handleMPPopup, closePay, mpStatus}) => {
  return (
    <div className="popup">
      <div className="popup-close"><a href='#' onClick={closePay}>X</a></div>
      <div className="popup-content w-100">
        <div className="login-box">
          <h2>Selecciona tu Medio de Pago</h2>
          {mpStatus && (
          <a href="#" onClick={handleMPPopup} className="social-button" id="mercadopago-connect"> <span>Pagar con Mercado Pago</span></a>
          )}
          <a href="#" onClick={handleMetaLogin} className="social-button" id="metamask-connect"> <span>Pagar con Metamask</span></a>
        </div>       
      </div>
    </div>
  );
};

export default Pay;