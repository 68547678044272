import React from 'react'

const QuienesSomos = () => {
  return (
    <div className="page-content">
      <div className="container">
        <ul className="breadcrumbs">
          <li><a href="/">Inicio</a></li>
          <li><span>Quienes Somos</span></li>
        </ul>
      </div>
      <div className="holder">
        <div className="container">
          {/*<!-- Page Title -->*/}
          <div className="page-title text-center">
            <h1>Quienes Somos</h1>
          </div>
          {/*<!-- /Page Title -->*/}
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-12">
              <p>MATIC MARKET, es un emprendimiento nacido en el año 2023, el cual fue concebido, desarrollado y basado exclusivamente en tecnología blockchain y utilizando los últimos y mas altos estándares de calidad y seguridad.</p>
              <p>Somos una tienda WEB3 donde se pueden comprar y vender productos únicos que están tokenizados en formato NFT, y los cuales son avalados y certificados (Proof of exixstance) por cada una de las bodegas y productores.</p>
              <p></p>
            </div>
            <div className="col-lg-10 col-xl-12">
              <h2>Misión</h2>
              <p></p>
              <p>Nuestra misión es acercar por medio de la tecnología blockchain a productores y desarrolladores de productos físicos con compradores de todos el mundo.</p>
              <p></p>
            </div>
            <div className="col-lg-10 col-xl-12">
              <h2>Visión</h2>
              <p></p>
              <p>Nuestra visión para el futuro de Matic Market es establecer nuestra tienda WEB3 como vehículo global en donde converjan en la misma compradores y vendedores de todas partes. </p>
              <p>Poder potenciar, simplificar y facilitar con esta plataforma el acceso a mercados a escala global para los productores y desarrolladores de productos sin importar en donde su emprendimiento se encuentre localizado.</p>
              <p>Ofrecerle al comprador una experiencia única y exclusiva al momento de realizar la compra facilitándole la transaccionalidad para la adquisición del producto y la transferencia del producto tokenizado.</p>
              <p>Ser el puente entre distintos demandantes del producto, en donde puedan comprar y vender libremente el mismo de acuerdo a su conveniencia y /o necesidad.</p>
              <p></p>
            </div>
            <div className="col-lg-10 col-xl-12">
              <h2>Valores</h2>
              <p></p>
              <p>En Matic Market valoramos la innovación,  el desarrollo y el establecimiento del vínculos a largo plazo.</p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuienesSomos
