import React from 'react'
import Constants from '../constants'

const Terms = ({acceptTerms, rejectTerms}) => {
  
  const userAgent = navigator.userAgent;
    console.log("User Agent:", userAgent);
  
    if (/Mobi|Android/i.test(userAgent) && !/MetaMask/i.test(userAgent)) {
      window.location.href = "https://metamask.app.link/dapp/https://maticmarket.io/?filter=";
      //window.location.href = "https://metamask.app.link/dapp/http://192.168.0.54:3000";
    } else {
      
  
  
  return (
        <div className="popup">
        <div className="popup-content w-100">
          <h2>Términos y Condiciones</h2>
          <div className="terms-content">
            <iframe src='/files/Terminos-y-condiciones-MATIC-MARKET-v7.pdf' width={'100%'} height={'200px'}></iframe>
          </div>
          <div className="buttons mt-4">
            <button className="btn" onClick={acceptTerms}>Aceptar</button>
            <button className="btn btn-secondary" onClick={rejectTerms}>Rechazar</button>
          </div>
        </div>
      </div>
    )
}
}

export default Terms
