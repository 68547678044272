import React from 'react'
import Constants from '../constants'

const Step = () => {
    return (
      <div className="steps container-fluid">
        <div className="text-center">
          <h2>Como usar Matic Market </h2>
          <h3 style={{ color: 'white' }}>Click en cada figura para un video explicativo</h3>
          <div className="row row-flex mt-3 text-center p-4">
            {Constants.steps.map((element, index) => {
              const openLinkInNewTab = () => {
                window.open(element.link, '_blank');
              };
  
              return (
                <div className="col-6 col-md-3 step-card" key={index} onClick={openLinkInNewTab} style={{ cursor: 'pointer' }}>
                  <div className="step-info">
                    <h3>{index + 1}</h3>
                    <div className='step-icon'><img src={element.icon} alt={`Step ${index + 1}`} /></div>
                    <p><span dangerouslySetInnerHTML={{ __html: element.text }}></span></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  
  export default Step;
  
