import React from 'react';
import {
  initMercadoPago,
  createCardToken,
  CardNumber,
  SecurityCode,
  ExpirationDate,
  getIdentificationTypes,
  getPaymentMethods,
  getIssuers,
  getInstallments,
  CardPayment ,
} from '@mercadopago/sdk-react';

/* Colocar el PUBLIC_KEY de Mercado Pago */
initMercadoPago('APP_USR-d2e980fc-9b8f-4347-a88f-2ddc927d4435'); 

const Checkout = ({userLogin, wine, amount, handleMintNFTCard, closeMP, setTitleMessage, setTextMessage, setShowMessagePopup}) => {
    console.log(wine);
    const initialization = {
        amount: amount,
        transaction_amount:amount,
        payer: {
            email: userLogin.email,
          }
       };
    const customization = {
        visual: {
          hideFormTitle: false,
          hidePaymentButton: false
        },
          paymentMethods: {
            maxInstallments: 1,
          }
      };       
       
       const onSubmit = async (formData) => {
        formData['wine'] = wine;
        console.log(formData);
        return new Promise((resolve, reject) => {
          fetch('https://palabackendserver-9e979415d339.herokuapp.com/api/mercadopago', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          })
            .then((response) => response.json())
            .then((response) => {
              // recibir el resultado del pago
              console.log('Evento Resultado', response);
              if (response.status =='approved') {
                handleMintNFTCard();
              } else {
                setTitleMessage('Ups! Parece que algo anda mal!')
                setTextMessage('Intenta nuevamente en unos minutos')
                setShowMessagePopup(true);
                closeMP();
              }              
              //resolve();
            })
            .catch((error) => {
                console.log('Evento Error', error);
                closeMP();
              // manejar la respuesta de error al intentar crear el pago
              reject();
            });
        });
       };
       
       
       const onError = async (error) => {
        closeMP();
        // callback llamado para todos los casos de error de Brick
        console.log(error);
       };
       
       
       const onReady = async () => {
        console.log('Evento Ready');
        /*
          Callback llamado cuando Brick está listo.
          Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
        */
       };
       
       
 return (
    <div className="popup">
        <div className="popup-close"><a href='#' onClick={closeMP}>X</a></div>
        <div className="popup-content popup-overflow w-100">
            <CardPayment
            initialization={initialization}
            customization={customization}
            description="Producto Test"
            onSubmit={onSubmit}
            onReady={onReady}
            onError={onError}
            />  
        </div>
    </div>
    )
};

export default Checkout;


   